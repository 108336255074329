import {CurrencyInput, Dialog, SnackbarService, withDevice} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";
import {memo, useCallback, useState} from "react";

import OrderService from "Services/OrderService";

const OrderCashRefundDialog = memo(({
	amountOwing,
	open,
	onClose,
	onComplete,
	targetOrderId
}) => {

	const [value, setValue] = useState((amountOwing ? (amountOwing / 100).toFixed(2).toString() : null));
	const [isSubmitting, setIsSubmitting] = useState(false);


	const refundAmount = Math.round((parseFloat(value) * 100));


	const handleSubmit = useCallback(async () => {

		if (!refundAmount || (refundAmount < 0)) {
			SnackbarService.snack("You must enter an amount to refund.", "error");
			return;
		}
		else if (refundAmount > amountOwing) {
			SnackbarService.snack(`The maximum refund you can issue is ${Localisation.formatCurrency(amountOwing)}.`, "error");
			return;
		}

		setIsSubmitting(true);

		try {

			await OrderService.recordPayments(targetOrderId, [
				CheckoutBasketPayment.construct({
					PaymentProcessor: PaymentProcessorsEnum.HOPS,
					PaymentProcessorData: {
						TenderedAmount: -refundAmount,
						Change: 0
					},
					PaymentType: PaymentTypes.Cash,
					TenderedAmount: -refundAmount,
					Value: -refundAmount
				})
			]);

			onComplete();

		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setIsSubmitting(false);

	}, [amountOwing, onComplete, refundAmount, targetOrderId]);


	return (
		<Dialog
			disabled={isSubmitting}
			loading={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			title={`Issue Cash Refund`}>
			<CurrencyInput
				autoFocus={true}
				error={(refundAmount > amountOwing)}
				helperText={((refundAmount > amountOwing) ? `The maximum refund you can issue is ${Localisation.formatCurrency(amountOwing)}.` : undefined)}
				disabled={isSubmitting}
				onChange={setValue}
				value={value} />
		</Dialog>
	);

});

export default withDevice(OrderCashRefundDialog);
