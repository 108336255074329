import {Dialog, Flex, Hidden, Paper, SnackbarService, String, withMobile} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";
import {PaymentElement} from "@stripe/react-stripe-js";
import {useCallback, useEffect, useState} from "react";
import OrgStripeProvider from "Stripe/OrgStripeProvider";

import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";
import StripeService from "Services/StripeService";

import PaymentProcessorBase from "../PaymentProcessorBase";

import scss from "./PaymentProcessorStripeMoto.module.scss";

/**
 * Process a payment made with Stripe PaymentElements.
 * 
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @param {object} Registration the POS registration to check the organisation is configured for Stripe.
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} Settings the device settings to find which reader is configured.
 * @returns JSX
 */
const PaymentProcessorStripeMoto = ({isMobile, onPaymentFailure, onPaymentSuccess, order, Registration, RequestedAmount}) => {

	const [tenderedAmount, setTenderedAmount] = useState();
	const [progressMessage, setProgressMessage] = useState("");
	const [stripeContext, setStripeContext] = useState(null);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const orgDefaultPaymentMethodId = Registration?.Org?.DefaultPaymentMethod?.Id;

	/**
	 * Stripe API initialisation error handler.
	 * @return {void}
	 */
	const handleStripeApiInitError = useCallback(() => {
		SnackbarService.snack("Stripe API not initialised.", "error");
	}, []);


	/**
	 * Runs the Stripe payment flow using the Stripe provider.
	 * @return {Promise}
	 */
	const handleOK = async () => {

		dPaymentsInProgress(true);
		setIsSubmitting(true);

		// Run the Stripe payment method workflow for the tendered amount and then store it with the payment API
		try {

			const {stripe, elements} = stripeContext;

			/**
			 * Check Stripe is initialised
			 */
			if (!stripe || !elements) {
				handleStripeApiInitError();
				return;
			}


			/**
			 * Create the Stripe payment method
			 */
			const {paymentMethod: stripePaymentMethod, error: paymentMethodError} = await stripe.createPaymentMethod({elements});


			/**
			 * Submit the payment to HOPS
			 */
			if (stripePaymentMethod && !paymentMethodError) {
				await hopsStorePayment(stripePaymentMethod);
			}
			else SnackbarService.snack(`There was an error processing the payment. (${paymentMethodError})`, "error");

		}
		catch (e) {
			SnackbarService.snack(StripeService.resolveErrorCode(e));
		}

		setIsSubmitting(true);
		dPaymentsInProgress(false);
		dPaymentType(null);

		return;
	};


	/**
	 * Show an on screen snackbar if something goes wrong.
	 * @param {string} message The message text to show to the end user.
	 */
	const handleError = function (message) {
		setProgressMessage(null);
		SnackbarService.snack(message, "error");
	};


	/**
	 * Show a progress update as the status of the payment flow changes.
	 * @param {string} message The message text to show to the end user.
	 */
	const handleProgressChange = function (message) {
		setProgressMessage(message);
	};


	/**
	 * Send the Stripe payment result to the HOPS API for storing/validation.
	 * We also store a local copy of this in redux and send it with the Basket
	 * at checkout time.
	 * 
	 * @param {Promise} payment The Stripe payment->paymentIntent response
	 * @return {Promise|null} 
	 */
	const hopsStorePayment = async payment => {

		handleProgressChange("Receiving Confirmation from Stripe");

		if (!orgDefaultPaymentMethodId) handleError("Payment method invalid (only org payment methods are supported)");

		/*
		 * Pass the result back to the checkout
		 */
		const result = await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				Moto: true,
				PaymentProcessor: PaymentProcessorsEnum.Stripe,
				PaymentProcessorData: {
					StripePaymentMethodId: payment.id
				},
				PaymentMethod: {
					Id: orgDefaultPaymentMethodId,
					Type: PaymentTypes.Card
				},
				TenderedAmount: Number(tenderedAmount),
				/**
				 * Normally we would send the value returned from
				 * the Payment Intent, but we don't get that back
				 * here. The backend does it in chargePaymentRequest
				 */
				Value: Number(tenderedAmount)
			})
		]);

		if (result?.error) {
			handleError("Unable to update HOPS with the confirmed payment. The customer has not been charged.");
		}
		else if (result?.paymentIntent) {
			return result;
		}

		return null;
	};

	/**
	 * User pressed the Cancel button. Our parent component decides what to do.
	 * @returns {Promise}
	 */
	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};

	/**
	 * Opening the submission confirmation dialog.
	 * @async
	 * @param {Event} e
	 * @return {void}
	 */
	const handleConfirmDialogOpen = useCallback(async e => {

		e?.preventDefault?.();

		const elements = stripeContext?.elements;

		/**
		 * Check we actually have an amount to charge
		 */
		if (!tenderedAmount) {
			SnackbarService.snack("You must enter an amount to charge.", "error");
			return;
		}

		/**
		 * Check Stripe is initialised
		 */
		if (!elements) {
			handleStripeApiInitError();
			return;
		}

		/**
		 * Validate Stripe inputs
		 */
		const {error: stripeValidationError} = await stripeContext.elements.submit();
		if (stripeValidationError) return;

		setIsConfirmDialogOpen(true);

	}, [tenderedAmount, stripeContext, handleStripeApiInitError]);


	/**
	 * Closing the submission confirmation dialog.
	 * @return {void}
	 */
	const handleConfirmDialogClose = useCallback(() => {
		setIsConfirmDialogOpen(false);
	}, []);


	/**
	 * A hook to set the tendered amount to the request amount.
	 * This has the effect of pre-populating the card payment value with the whole amount.
	 */
	useEffect(() => {
		setTenderedAmount(RequestedAmount);
	}, [RequestedAmount]);


	/**
	 * Render everything
	 */
	return (
		<Flex className={scss.root}>
			<String bold={true} str="Stripe MOTO Card Payment" variant="h5" />
			<Flex columnar={!(isMobile)} justifyContent="center">
				<Paper classNamePaper={scss.motoPane}>
					<Flex alignItems="center">
						<String bold={true} str="Customer Details" variant="h6"/>
						{progressMessage}
						{/**
						 * The Stripe form/element must remain loaded for the entire transaction
						 * In other Payment Processors we generally hide the payment UI while PaymentsInProgress=true
						 */}
						<OrgStripeProvider
							onUpdateContext={setStripeContext}
							paymentAmount={tenderedAmount}>
							<form
								onSubmit={handleConfirmDialogOpen}>
								{/** Stripe does not support rendering with <=0 amounts */}
								<Hidden hidden={(!tenderedAmount || (tenderedAmount < 0))}>
									<Flex mb={1}>
										{(
											!!tenderedAmount &&
										<PaymentElement
											options={{
												defaultValues: {
													billingDetails: {
														name: (`${(order?.BillingContact?.Fname || "")} ${(order?.BillingContact?.Sname || "")}`.trim() || undefined),
														email: (order?.BillingContact?.Email || undefined),
														phone: (order?.BillingContact?.Phone || undefined),
														address: {
															line1: (order?.BillingContact?.Address?.Address || undefined),
															city: (order?.BillingContact?.Address?.City || undefined),
															state: (order?.BillingContact?.Address?.County || undefined),
															country: (order?.BillingContact?.Address?.Country || undefined),
															postal_code: (order?.BillingContact?.Address?.Postcode || undefined)
														}
													}
												}
											}} />
										)}
									</Flex>
								</Hidden>
								<Dialog
									title="Charge Payment"
									str={`Are you sure you want to charge this payment (${Localisation.formatCurrency(tenderedAmount)}) to the customer's card?`}
									loading={isSubmitting}
									onClose={handleConfirmDialogClose}
									onOk={handleOK}
									open={isConfirmDialogOpen} />
							</form>
						</OrgStripeProvider>
					</Flex>
				</Paper>
				<Paper classNamePaper={scss.motoPane}>

					<PaymentProcessorBase
						onCancel={handleCancel}
						onChange={setTenderedAmount}
						onOK={handleConfirmDialogOpen}
						RequestedAmount={RequestedAmount}
						value={tenderedAmount} />
				</Paper>
			</Flex>
		</Flex>
	);

};

export default withBasket(withMobile(PaymentProcessorStripeMoto));
