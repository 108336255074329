import {CurrencyInput, Dialog, SnackbarService, String, withDevice} from "@hps/hops-react";
import {Api, Localisation} from "@hps/hops-sdk-js";
import {memo, useCallback, useState} from "react";


const OrderVoucherRefundDialog = memo(({
	amountOwing,
	apiBaseUrl,
	open,
	onClose,
	onComplete,
	targetOrderId
}) => {

	const [value, setValue] = useState((amountOwing ? (amountOwing / 100).toFixed(2).toString() : null));
	const [isSubmitting, setIsSubmitting] = useState(false);


	const refundAmount = Math.round((parseFloat(value) * 100));


	const handleSubmit = useCallback(async () => {

		if (!refundAmount || (refundAmount < 0)) {
			SnackbarService.snack("You must enter an amount to issue.", "error");
			return;
		}
		else if (refundAmount > amountOwing) {
			SnackbarService.snack(`The maximum refund you can issue is ${Localisation.formatCurrency(amountOwing)}.`, "error");
			return;
		}

		setIsSubmitting(true);

		try {

			await Api.call({
				url: `${apiBaseUrl}/orders/${targetOrderId}/voucher`,
				method: "POST",
				data: {
					Amount: refundAmount
				}
			});

			onComplete();

		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setIsSubmitting(false);

	}, [
		amountOwing,
		apiBaseUrl,
		onComplete,
		refundAmount,
		targetOrderId
	]);

	return (
		<Dialog
			disabled={isSubmitting}
			loading={isSubmitting}
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			title={`Refund to New Voucher`}>
			<String
				color="textSecondary"
				gap={0.5}
				str={`A new voucher will be issued for the amount you enter. It will expire after 12 months.\nThe customer will be automatically emailed the voucher's details (if a billing email address has been recorded).`} />
			<div />
			<CurrencyInput
				autoFocus={true}
				error={(refundAmount > amountOwing)}
				helperText={((refundAmount > amountOwing) ? `The maximum refund you can issue is ${Localisation.formatCurrency(amountOwing)}.` : undefined)}
				disabled={isSubmitting}
				onChange={setValue}
				value={value} />
		</Dialog>
	);

});

export default withDevice(OrderVoucherRefundDialog);
