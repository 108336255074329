import {Flex, String} from "@hps/hops-react";
import {TicketJourneyTypes} from "@hps/hops-sdk-js";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";

import ItemCardLabel from "Components/ItemCardLabel.js";
import withInventory from "Hoc/withInventory.js";
import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";


/**
 * "Favourite" ticket travel search card
 *
 * Updates the tickets travel selection with the assigned data when selected.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const TicketsSearchFavouritesBrowserCard = props => {


	/**
	 * Get our search's travel date as DD/MM/YYYY.
	 *
	 * @return {String|undefined}
	 */
	const dateDmy = () => {
		if (props.search.Date) {
			return (new moment(props.search.Date)).format("DD/MM/YYYY");
		}
		else return undefined;
	};


	/**
	 * Get our timing points string.
	 *
	 * @return {String}
	 */
	const tpsString = () => {
		return [
			props.search.TimingPointA,
			props.search.TimingPointB,
			props.search.TimingPointC
		].filter(tp => tp).map(tp => {
			return props.Inventory.Tickets.TimingPoints.find(t => (t.Id === tp))?.Name;
		}).join(" - ");
	};


	/**
	 * Get our search's detail strings.
	 *
	 * @return {Array}
	 */
	const detailStrings = () => {

		const cls = props.Inventory.Tickets.Classes.find(c => (c.Id === props.search.Class))?.Name;
		const ft = props.Inventory.Tickets.FareTypes.find(ft => (ft.Id === props.search.FareType))?.Name;
		const journey = TicketJourneyTypes.getLabel(props.search.JourneyType);

		const details = [
			ft,
			`${cls} ${journey}`,
			props.search.PeopleCounts?.map(({Type, Count}) => {
				const typeName = props.Inventory.Tickets.Types.find(t => (t.Id === Type))?.Name;
				return `${Count} ${pluralize((typeName || ""), Count)}`;
			})
		];

		return details.map(details => (Array.isArray(details) ? details.join(" • ") : details));

	};

	return (
		<InventoryGridItemBase
			disabled={props.disabled}
			onClick={props.onSelect}
			value={props.search}>
			<Flex
				justifyContent="center"
				gap={0.25}
				height="100%">
				<ItemCardLabel
					label={props.search.Label}
					noCentring={true} />
				<String
					centre={true}
					noFlex={true}
					str={dateDmy()} />
				<String
					centre={true}
					noFlex={true}
					str={tpsString()} />
				<String
					centre={true}
					color="textSecondary"
					gap={0.25}
					str={detailStrings()} />
			</Flex>
		</InventoryGridItemBase>
	);

};

export default withInventory(TicketsSearchFavouritesBrowserCard);
