import {ErrorReporter} from "@hps/hops-react";
import {GiftCardInstanceService, Localisation, OrderableTypes, OrderVoucherService, TicketJourneyTypes} from "@hps/hops-sdk-js";
import moment from "moment";
import mustache from "mustache";

import {getPrintCountForBasketItem} from "./OrderStationeryUtils.js";


/**
 * Order stationery template renderer
 *
 * Responsible for actually rendering stationery templates.
 *
 * @package HOPS
 * @subackage Stationery
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrderStationeryTemplateRenderer {

	/**
	 * Render a stationery template for an item.
	 *
	 * @param {String} options.template Template HTML
	 * @param {Object} options.item CheckoutBasketItem-like object we're rendering for
	 * @param {Array<Object>} options.discounts BasketDiscountClaim-like objects
	 * @param {Integer} options.qtyIndex Index of the item quantity unit we're rendering
	 * @param {Integer} options.printIndex Index of the print we're rendering
	 * @param {Integer} options.printIndexThisQtyIndex Index of the print we're rendering, within the item quantity unit we're rendering
	 * @param {String} options.barcode Barcode for this stationery item
	 * @param {Integer} options.orderId Order ID we're rendering for
	 * @param {Integer} options.orderTimestamp Order timestamp
	 * @param {String} options.deviceIdentifier Identifier for the PoS device (i.e. this device) to show on stationery
	 * @param {Array<Object>} options.basketItems Other items in the basket (for template variable resolution)
	 * @param {Object} checkoutData Checkout API result data (to lookup item details where required, e.g. order voucher issuances)
	 * @return {String} html
	 */
	static render({
		template,
		item,
		discounts,
		qtyIndex,
		printIndex,
		printIndexThisQtyIndex,
		barcode,
		orderId,
		orderTimestamp,
		deviceIdentifier,
		basketItems,
		checkoutData
	}) {

		const orderMoment = new moment((orderTimestamp * 1000));
		const totalPrintsForItem = getPrintCountForBasketItem(item);


		/**
		 * Get the discounts that apply to this item/quantity index
		 */
		const applicableDiscounts = discounts.filter(discountClaim => {
			return ((discountClaim.DiscountQty > qtyIndex) &&
					(discountClaim.ItemClaim === item.Uuid));
		});

		/**
		 * Calculate the total discount amount
		 */
		const totalDiscountAmount = applicableDiscounts.reduce((a, b) => (a + b.Discount.Amount), 0);


		/**
		 * Get the actual item price after discounts
		 */
		const itemPriceAfterDiscounts = Math.max((item.Price - totalDiscountAmount), 0);


		/**
		 * Price display text
		 */
		const displayPrice = Localisation.formatCurrency(itemPriceAfterDiscounts);


		/**
		 * Basket item-specific variables
		 */
		let basketItemTemplateVariables = {};

		switch (item?.OrderableType) {

			case OrderableTypes.GiftCardInventory:
				basketItemTemplateVariables = this.getGiftCardTemplateVariables(item, qtyIndex, checkoutData);
				break;

			case OrderableTypes.TicketSession:
			case OrderableTypes.TicketTravel:
				basketItemTemplateVariables = this.getTicketTemplateVariables(item, printIndex, basketItems);
				break;

			case OrderableTypes.VoucherSale:
				basketItemTemplateVariables = this.getVoucherSaleTemplateVariables(item, qtyIndex, checkoutData);
				break;

			default:
				basketItemTemplateVariables = {};
		}


		/**
		 * Get our variables
		 */
		const templateVariables = {
			order_id: `L${orderId}`,
			order_timestamp_date: orderMoment.format("YYYYMMDD"),
			order_timestamp_time: orderMoment.format("HHmmss"),
			item_print_no: (printIndexThisQtyIndex + 1),
			item_print_no_total: totalPrintsForItem,
			item_print_no_displayed: (totalPrintsForItem > 1),
			pos_device: deviceIdentifier,
			item_price: displayPrice,
			unit_price_display_price: ((printIndexThisQtyIndex === 0) ? displayPrice : null),
			unit_price_display_unit: ((printIndexThisQtyIndex > 0) ? `(${(printIndexThisQtyIndex + 1)} of ${totalPrintsForItem})` : null),
			stationery_id: barcode,
			...basketItemTemplateVariables
		};


		/**
		 * Hack as we want this Mustache variable to 
		 * still remain one, as Gateway injects the value
		 */
		templateVariables.barcode_image_data_src_png_base64 = "{{barcode_image_data_src_png_base64}}";


		/**
		 * Render the template!
		 */
		try {
			return mustache.render(template, templateVariables);
		}

		/**
		 * Mustache error
		 *
		 * We don't want a broken template to affect checkout, 
		 * so we will ignore Mustache and fallback to manually 
		 * replacing known template variables ourselves where 
		 * possible - we can't support sections etc. so it will 
		 * probably look broken, but at least it's *something*!
		 */
		catch (e) {

			ErrorReporter.report(e);

			for (const variable of Object.keys(templateVariables)) {
				template = template.replaceAll(`{{${variable}}}`, templateVariables[variable]);
			}

			return template;

		}

	}


	/**
	 * Get the item-specific variables for a Gift Card
	 * 
	 * @param {Object} item CheckoutBasketItem-like object we're rendering for
	 * @param {Integer} qtyIndex Index of the item quantity unit we're rendering
	 * @param {Object} checkoutData Checkout API result data (to lookup item details where required, e.g. gift card issuances)
	 * @return {Object}
	 */
	static getGiftCardTemplateVariables(item, qtyIndex, checkoutData) {

		let giftCardInstance = null;

		// Newly Issued Gift Card
		if (checkoutData?.PurchasedGiftCards) {
			giftCardInstance = checkoutData?.PurchasedGiftCards[item.Uuid][qtyIndex];
		}

		// Recalled Gift Card
		if (item?.Item?.GiftCards) {
			giftCardInstance = item.Item.GiftCards[qtyIndex];
		}

		/**
		 * Create our variables!
		 */
		return {
			product_name: giftCardInstance?.Inventory?.NameStationery,
			code: (giftCardInstance?.Code ? GiftCardInstanceService.getGiftCardCodeDisplayString(giftCardInstance.Code) : null),
			expiry_date: (giftCardInstance?.ExpiryDate ? Localisation.formatDateTime(giftCardInstance?.ExpiryDate, Localisation.DateTimeFormats.LongDate) : null)
		};

	}


	/**
	 * Get the item-specific variables for a Ticket (Travel or Session)
	 * 
	 * @param {Object} item CheckoutBasketItem-like object we're rendering for
	 * @param {Integer} printIndex Index of the print we're rendering, within the item quantity unit
	 * @param {Array<Object>} basketItems Other items in the basket (for resolution of variables which refer to dependent items)
	 * @return {Object}
	 */
	static getTicketTemplateVariables(item, printIndex, basketItems) {

		const now = new moment();
		const journeys = item?.Item?.Journeys;
		const travelDate = (item?.Item?.Date ? new moment(item.Item.Date) : null);

		/**
		 * Fare/type details
		 */
		const travelFare = item?.Item?.TicketOption?.Fares?.find?.(f => (f?.Id === item?.Item?.FareId));
		const fareType = (travelFare?.Type || item?.Item?.FareType);
		const ticketType = (item?.Item?.TicketOption?.Type || item?.Item?.TicketType);

		/**
		 * Get seat reservations for this item
		 */
		let sessionSeat = null;
		const seats = basketItems?.filter(i => {
			return ((i.OrderableType === OrderableTypes.SeatReservation) &&
					(i.RelatedItemUuid === item.Uuid));
		});

		// For sessions there should only be one seat, which is the index of our current ticket
		if (item?.OrderableType === OrderableTypes.TicketSession) {
			sessionSeat = seats[printIndex];
		}

		/**
		 * Utility function to find the seats related to a journey object
		 *
		 * @param {Object} journey Journey-like object
		 * @return {Array<Object>} CheckoutBasketItem-like seat objects
		 */
		const findSeatsForJourney = journey => {
			return (seats?.filter(s => {
				return ((s?.Item?.DepartureTrainSchedule === journey?.Departure?.Id) &&
						(s?.Item?.ArrivalTrainSchedule === journey?.Arrival?.Id));
			}));
		};


		/**
		 * Prepare the template variables for one of our journeys by its index.
		 *
		 * @param {Integer} index
		 * @return {Object|null}
		 */
		const prepareJourneyTemplateVariables = index => {
			const journey = journeys?.[index];
			if (!journey) return null;
			else return this.prepareJourneyTemplateVariables(journey, (index + 1), findSeatsForJourney(journey)?.[printIndex]);
		};


		/**
		 * Get session date/time properties
		 */
		const sessionStartDate = item?.Item?.Session?.StartDate;
		const sessionStartTime = item?.Item?.Session?.StartTime;
		const sessionEndDate = item?.Item?.Session?.EndDate;
		const sessionEndTime = item?.Item?.Session?.EndTime;

		/**
		 * Determine presentation of our session date/time properties
		 */
		const sessionStartDateVar = (sessionStartDate ? Localisation.formatDateTime(sessionStartDate, Localisation.DateTimeFormats.LongDate) : null);
		const sessionEndDateVar = ((sessionEndDate && (sessionEndDate !== sessionStartDate)) ? Localisation.formatDateTime(sessionEndDate, Localisation.DateTimeFormats.LongDate) : null);

		/**
		 * Date/time var handling
		 */
		let sessionStartDateTimeVar = this.formatTimeString(sessionStartTime);
		let sessionEndDateTimeVar = null;

		/**
		 * Determine how to handle the end time
		 */
		if (sessionEndTime) {

			sessionStartDateTimeVar += ` - `;
			const sessionEndTimeFormatted = this.formatTimeString(sessionEndTime);

			if ((sessionStartDate === sessionEndDate) || !sessionEndDate) {
				sessionStartDateTimeVar += sessionEndTimeFormatted;
			}
			else sessionEndDateTimeVar = sessionEndTimeFormatted;

		}


		/**
		 * Create our variables!
		 */
		return {
			product_name: item?.Item?.Session?.Product?.Name,
			ticket_class: item?.Item?.TicketOption?.Class?.NameShort,
			fare_type_when_not_normal: (!fareType?.Normal ? fareType?.Name : null),
			ticket_type: ticketType?.Name,
			...prepareJourneyTemplateVariables(0),
			...prepareJourneyTemplateVariables(1),
			...prepareJourneyTemplateVariables(2),
			seat: (sessionSeat ? this.renderSeatLabel(sessionSeat) : "Any"), // For Sessions (without a journey)
			journey_type: (item?.Item?.TicketOption?.Journey ? TicketJourneyTypes.getLabel(item.Item.TicketOption.Journey) : null),
			start_date: sessionStartDateVar,
			start_date_time: sessionStartDateTimeVar,
			end_date: sessionEndDateVar,
			end_date_time: sessionEndDateTimeVar,
			travel_date: travelDate?.format("DD/MM/YYYY"),
			adv: ((travelDate && (travelDate > now)) ? "ADV" : null)
		};

	}


	/**
	 * Get the item-specific variables for a Voucher
	 * 
	 * @param {Object} item CheckoutBasketItem-like object we're rendering for
	 * @param {Integer} qtyIndex Index of the item quantity unit we're rendering
	 * @param {Object} checkoutData Checkout API result data (to lookup item details where required, e.g. order voucher issuances)
	 * @return {Object}
	 */
	static getVoucherSaleTemplateVariables(item, qtyIndex, checkoutData) {

		let voucherInstance = null;
		let voucherBalanceIssued = null;
		let voucherBalanceAvailable = null;

		// Newly Issued Voucher
		if (checkoutData?.PurchasedOrderVouchers) {
			voucherInstance = checkoutData?.PurchasedOrderVouchers[item.Uuid][qtyIndex];
			voucherBalanceIssued = Localisation.formatCurrency(voucherInstance?.Balance);
		}

		// Recalled Voucher
		if (item?.Item?.Vouchers) {
			voucherInstance = item.Item.Vouchers[qtyIndex];
			voucherBalanceIssued = `${Localisation.formatCurrency(voucherInstance?.IssuedBalance)}`;
			voucherBalanceAvailable = `${Localisation.formatCurrency(voucherInstance?.AvailableBalance)}`;
		}

		/**
		 * Create our variables!
		 */
		return {
			code: (voucherInstance?.Code ? OrderVoucherService.getVoucherCodeDisplayString(voucherInstance.Code) : null),
			expiry_date: (voucherInstance?.ExpiryDate ? Localisation.formatDateTime(voucherInstance?.ExpiryDate, Localisation.DateTimeFormats.LongDate) : null),
			balance_issued: voucherBalanceIssued,

			// Available balance won't be rendered if null (i.e. Available === Issued)
			balance_available: voucherBalanceIssued === voucherBalanceAvailable ? null : voucherBalanceAvailable
		};

	}


	/**
	 * Format a time string ready to present.
	 *
	 * @param {String} time HH:MM:SS
	 * @return {String}
	 */
	static formatTimeString(time) {
		return time?.substring(0, 5);
	}


	/**
	 * Get the template variables to provide for a journey object.
	 *
	 * @param {Object|null} journey Journey
	 * @param {Integer} index Journey's index
	 * @param {Object|null} seat CheckoutBasketItem-like object
	 * @return {Object} Journey's template variables
	 */
	static prepareJourneyTemplateVariables(journey, index, seat) {
		return {
			[`journey_${index}_arrival_time`]: (journey ? this.formatTimeString(journey?.Arrival?.ArrivalTime) : null),
			[`journey_${index}_arrival_tp`]: (journey ? journey?.Arrival?.TimingPoint?.NameShort : null),
			[`journey_${index}_departure_time`]: (journey ? this.formatTimeString(journey?.Departure?.DepartureTime) : null),
			[`journey_${index}_departure_tp`]: (journey ? journey?.Departure?.TimingPoint?.NameShort : null),
			[`journey_${index}_departure_arrival_line`]: `${journey?.Departure?.TimingPoint?.Name || journey?.Departure?.TimingPoint?.NameShort || null} to ${journey?.Arrival?.TimingPoint?.Name || journey?.Arrival?.TimingPoint?.NameShort || null}`,
			[`journey_${index}_seat`]: (seat ? this.renderSeatLabel(seat) : (journey ? "Any" : null))
		};
	}


	/**
	 * Create the text to show on the stationery for a seat item
	 * 
	 * @param {Object} seat CheckoutBasketItem-like object
	 * @return {String}
	 */
	static renderSeatLabel(seat) {
		if (!seat) return "";
		else return `${seat?.Item?.TrainAsset?.Letter} ${(seat?.Item?.Space?.Label || seat?.Item?.Space?.Id)}`.trim();
	}

}

export default OrderStationeryTemplateRenderer;
