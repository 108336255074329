import {UnhandledErrorView, withRegistration} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import {useTheme} from "@mui/styles";
import {ErrorBoundary} from "@sentry/react";
import moment from "moment";
import React, {useEffect, useMemo, useRef, useState} from "react";

const TimetableGraph = props => {

	const theme = useTheme();

	const canvasRef = useRef(null);
	const canvasWrapperRef = useRef(null);

	const [currentTime, setCurrentTime] = useState(new Date());

	const serviceLineColour = useMemo(() => (theme.palette.primary.main), [theme]);
	const Services = useMemo(() => (props.services || []), [props]);
	const TimingPoints = useMemo(() => (props.Registration?.Org?.TimingPoints || []), [props]);

	useEffect(() => {

		const operatingDate = new moment().format("YYYY-MM-DD");
		const XAxisUnits = 1800000; // milliseconds
		let graphBounds = {};

		// Flatten all the schedules out to a single list
		const allSchedules = Services.map(x => x.Schedules).flatMap(schedule => schedule);

		// Find the earliest schedule
		const dayStart = new Date(
			Math.min(...allSchedules
				.filter(x => x.DepartureTime !== null)
				.map(e => new Date(`${operatingDate} ${e.DepartureTime}`)))
		);

		// Find the latest schedule
		const dayEnd = new Date(
			Math.max(...allSchedules
				.filter(x => x.ArrivalTime !== null)
				.map(e => new Date(`${operatingDate} ${e.ArrivalTime}`)))
		);

		/**
		 * Convert a date to an X co-ordinate inside the current graph bounds.
		 * 
		 * @param {Date} timeOfDay
		 * @returns {number} The X co-ordinate for that time on the graph
		 */
		const convertTimeOfDayToXCoord = timeOfDay => {
			return graphBounds["Y"] + ((timeOfDay - dayStart) / (dayEnd - dayStart)) * graphBounds["Width"];
		};


		/**
		 * Convert a decimal mileage to a Y co-ordinate inside the current graph bounds.
		 * 
		 * @param {number} distance The decimal mileage
		 * @returns {number} The Y co-ordinate for that mileage on the graph
		 */
		const convertDistanceToYCoord = distance => {

			if (TimingPoints) {
				const MileageStart = TimingPoints[Object.keys(TimingPoints)[0]].Mileage.Decimal;
				const MileageEnd = TimingPoints[Object.keys(TimingPoints)[Object.keys(TimingPoints).length - 1]].Mileage.Decimal;
				return graphBounds["X"] + ((distance - MileageStart) / (MileageEnd - MileageStart)) * graphBounds["Height"];
			}

			return 0;
		};


		/**
		 * Proportionally resize the canvas to 100% width of its parent
		 */
		const resizeCanvas = () => {

			const canvasCurrent = canvasRef?.current;
			const canvasBoundCurrent = canvasWrapperRef?.current;

			// Fill the container
			const width = canvasBoundCurrent?.offsetWidth;
			const canvasWidth = width;

			const canvasHeight = width / 5;

			if (canvasCurrent.width !== canvasWidth || canvasCurrent.height !== canvasHeight) {

				const {devicePixelRatio: ratio=1} = window;

				const context = canvasCurrent.getContext("2d");
				canvasCurrent.width = canvasWidth * ratio;
				canvasCurrent.height = canvasHeight * ratio;
				context.scale(ratio, ratio);

			}

			graphBounds = {
				X: 25,
				Y: 25,
				Width: canvasWidth - 40,
				Height: canvasHeight - 45
			};

		};


		/**
		 * Handle re-sizing the window
		 */
		const handleResize = () => {
			resizeCanvas();
			drawTimetable();
		};


		/**
		 * Draw the current timetable data on the canvas graph
		 */
		const drawTimetable = () => {

			const canvasCurrent = canvasRef?.current;
			const canvasContext = canvasCurrent?.getContext("2d");

			// canvasContext.font = '12px "Segoe UI",Arial,sans-serif';
			canvasContext.strokeStyle = "#e0e0e0";
			canvasContext.fillStyle = "#6c757d";
			canvasContext.lineWidth = 1;

			/**
			 * X-Axis Labels (Time of Day)
			 */
			canvasContext.textAlign = "center";

			for (let i = dayStart; i <= dayEnd; i = new Date(i.getTime() + XAxisUnits)) {

				const x = convertTimeOfDayToXCoord(i);

				canvasContext.fillText(Localisation.formatDateTime(i, Localisation.DateTimeFormats.ShortTime), x, graphBounds["X"] - 5);

				canvasContext.beginPath();
				canvasContext.moveTo(x, graphBounds["X"]);
				canvasContext.lineTo(x, graphBounds["X"] + graphBounds["Height"]);
				canvasContext.closePath();
				canvasContext.stroke();
			}

			/**
			 * Y-Axis Labels (Timing Points)
			 */
			if (TimingPoints.length) {
				canvasContext.textAlign = "left";

				for (const timingPoint of TimingPoints) {

					const y = convertDistanceToYCoord(timingPoint.Mileage.Decimal);

					canvasContext.beginPath();
					if (!timingPoint.Station) canvasContext.setLineDash([5]);
					canvasContext.moveTo(graphBounds["Y"], y);
					canvasContext.lineTo(graphBounds["Y"] + graphBounds["Width"], y);
					canvasContext.closePath();
					canvasContext.stroke();

					canvasContext.setLineDash([]); // Reset the line style

					// Add a label for stations
					if (timingPoint.Station) canvasContext.fillText(`${timingPoint.Mileage.Miles}mi ${timingPoint.Mileage.Chains}ch, ${timingPoint.Name}`, graphBounds["Y"] + 5, y + 10);
				}
			}

			/**
			 * Services
			 */
			if (Services.length) {
				for (const service of Services) {

					// Remove any "Pass" timing points with no arrival and no departure
					const chainedSchedule = service.Schedules.filter(schedule => !(schedule.DepartureTime === null && schedule.ArrivalTime === null));

					// Plot Diagram graph lines for each scheduled leg in this service
					for (let i = 0; i < chainedSchedule.length; i++) {

						const thisService = chainedSchedule[i];
						const followOnService = chainedSchedule[i+1];

						if (followOnService) {

							const thisTimingPoint = TimingPoints.find(t => t.Id === thisService.TimingPoint.Id);
							const followOnTimingPoint = TimingPoints.find(t => t.Id === followOnService.TimingPoint.Id);

							const x1 = convertTimeOfDayToXCoord(new Date(`${operatingDate} ${thisService.DepartureTime}`));
							const y1 = convertDistanceToYCoord(thisTimingPoint.Mileage.Decimal);

							const x2 = convertTimeOfDayToXCoord(new Date(`${operatingDate} ${followOnService.ArrivalTime}`));
							const y2 = convertDistanceToYCoord(followOnTimingPoint.Mileage.Decimal);

							canvasContext.strokeStyle = service.colour || serviceLineColour;
							canvasContext.lineWidth = 2;
							canvasContext.beginPath();
							canvasContext.moveTo(x1, y1);
							canvasContext.lineTo(x2, y2);
							canvasContext.closePath();
							canvasContext.stroke();

							// There's a layover
							if (thisService.DepartureTime !== thisService.ArrivalTime) {

								const x1 = convertTimeOfDayToXCoord(new Date(`${operatingDate} ${thisService.ArrivalTime}`));
								const x2 = convertTimeOfDayToXCoord(new Date(`${operatingDate} ${thisService.DepartureTime}`));
								const y1 = convertDistanceToYCoord(thisTimingPoint.Mileage.Decimal);
								const y2 = convertDistanceToYCoord(thisTimingPoint.Mileage.Decimal);

								canvasContext.strokeStyle = service.colour || serviceLineColour;
								canvasContext.lineWidth = 2;
								canvasContext.beginPath();
								canvasContext.moveTo(x1, y1);
								canvasContext.lineTo(x2, y2);
								canvasContext.closePath();
								canvasContext.stroke();

							}

						}

					}
				}
			}

			/**
			 * Current time marker line
			 */
			const now = new Date();

			if (dayStart <= now <= dayEnd) {

				canvasContext.strokeStyle = "#f00";
				const x = convertTimeOfDayToXCoord(now);
				canvasContext.beginPath();
				canvasContext.moveTo(x, graphBounds["X"]);
				canvasContext.lineTo(x, graphBounds["X"] + graphBounds["Height"]);
				canvasContext.closePath();
				canvasContext.stroke();

				/**
				 * Place us after the line in the morning and before
				 * the line in the afternoon so the label doesn't go off-canvas.
				 */
				const labelX = currentTime.getHours() >= 12 ? x - 30 : x + 5;

				canvasContext.fillStyle = "#f00";

				canvasContext.fillText(Localisation.formatDateTime(currentTime, Localisation.DateTimeFormats.ShortTime), labelX, graphBounds["Height"] + 20);

			}
		};

		/**
		 * Set an interval to refresh the current time, which forces us to re-render
		 * and update all our time-specific drawings (e.g. the current time marker line)
		 * 
		 * No seconds are visible so 4 times per minute is plenty
		 */
		const interval = setInterval(() => {
			setCurrentTime(new Date());
		}, 15000);

		// Initialise the graph size/data
		resizeCanvas();
		drawTimetable();

		// Add a handler to resize the graph if the window dimensions change
		window.addEventListener("resize", handleResize);

		// Clean up, remove the resize handler
		return () => {
			window.removeEventListener("resize", handleResize);
			clearInterval(interval);
		};

	}, [currentTime, setCurrentTime, Services, serviceLineColour, TimingPoints]);


	return (
		<ErrorBoundary fallback={UnhandledErrorView}>
			<div ref={canvasWrapperRef}>
				<canvas ref={canvasRef} ></canvas>
			</div>
		</ErrorBoundary>
	);

};

export default withRegistration(TimetableGraph);
