import {Banner, Button, Dialog, Divider, Flex, Paper, String} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";
import {createRef, useEffect, useState} from "react";

import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";
import ReceiptUtils from "Receipts/ReceiptUtils";


/**
 * Process a payment made with the Stripe Terminal app running separately.
 * 
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @returns JSX
 */
const PaymentProcessorHOPSPaymentApp = ({BasketIdentity, RequestedAmount, onPaymentFailure, onPaymentSuccess}) => {

	let iframeRef = createRef();
	const [svg, setSvg] = useState(null);
	const previewWidth = 320;
	const [qrDialogOpen, setQrDialogOpen] = useState(false);

	const paymentDeepLinkUri = `hopsp-payment://basket/${BasketIdentity.OfflineBasketUuid}/payment/${RequestedAmount}`;

	useEffect(() => {
		const generatedSvg = ReceiptUtils.getSvg(`{code:${paymentDeepLinkUri}; option:qrcode,6,L}`);
		setSvg(generatedSvg);
	}, [paymentDeepLinkUri]);


	const handleOK = async () => {

		dPaymentsInProgress(true);

		/*
		 * Pass the result back to the checkout
		 * Pressing "App" on PoS, type 9 (Placeholder) processor 10 (HOPS)
		 * The Stripe Payment Intent will be captured by the backend and replace this payment.
		 */
		await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.HOPS,
				PaymentType: PaymentTypes.Placeholder,
				TenderedAmount: Number(RequestedAmount),
				Value: RequestedAmount
			})
		]);

		dPaymentsInProgress(false);
		dPaymentType(null);

		return;
	};


	const handleOpenDeepLink = () => {
		window.open(paymentDeepLinkUri);
	};

	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};


	/**
	 * Receipt `iframe` loaded.
	 *
	 * We update its styles to match our layout.
	 *
	 * @return {void}
	 */
	const handleIframeLoad = () => {
		if (iframeRef) {

			const svg = iframeRef.contentDocument.querySelector("svg");
			const svgWidth = svg.width.baseVal.value;
			const svgHeight = svg.height.baseVal.value;
			const svgAspect = (svgHeight / svgWidth);

			const targetWidth = previewWidth;
			const targetHeight = Math.ceil((svgAspect * targetWidth));

			const svgStyles = `<style>
					@media not print {
						svg {
							width: ${targetWidth}px;
							height: ${targetHeight}px;
						}
					}
				</style>`;

			iframeRef.setAttribute("width", `${targetWidth}px`);
			iframeRef.setAttribute("height", `${targetHeight}px`);
			iframeRef.contentDocument.body.innerHTML += svgStyles;
			iframeRef.contentDocument.body.style.margin = 0;
			iframeRef.contentDocument.body.style.overflow = "hidden";
			iframeRef.contentDocument.body.style.textAlign = "center";

		}
	};


	const handleNewIframeRef = ref => (iframeRef = ref);


	const handleCloseDialog = () => {
		setQrDialogOpen(false);
	};


	return (
		<Flex>
			<String bold={true} str="HOPS Payment App" variant="h5" />
			<Paper>
				<Flex alignItems="center">

					<Banner str="Please take payment from the customer" />

					<String
						str={Localisation.formatCurrency(RequestedAmount)}
						variant="h6" />

					<String
						bold={true}
						str="Where is the HOPS Payment App installed?" />

					<Flex columnar={true}>
						<Button
							onClick={handleOpenDeepLink}
							label="On This Device"
							variant="contained" />
						<Button
							label="A Separate Device"
							onClick={() => setQrDialogOpen(true)}
							variant="contained" />
					</Flex>

					<Divider/>

					<String
						gap={0}
						str="When instructed by the HOPS Payment App tap Payment Received." />

					<Flex columnar={true}>
						<Button
							label="Payment Received"
							onClick={handleOK} />

						<Button
							label="Cancel"
							onClick={handleCancel} />
					</Flex>

					<Dialog
						open={qrDialogOpen}
						onClose={handleCloseDialog}
						title="HOPS Payment App">

						<Flex alignItems="center"
							justifyContent="center"
							flexGrow={1}>

							<String
								bold={true}
								color="primary"
								gap={0}
								str="Open the Camera on the device running the HOPS Payment App." />

							<String
								gap={0}
								str="Scan this QR code to begin the offline payment." />

							<iframe
								srcDoc={svg}
								onLoad={handleIframeLoad}
								ref={handleNewIframeRef}
								title="Payment Deep Link QR Code" />

							<a href={paymentDeepLinkUri}>{paymentDeepLinkUri}</a>

							<String
								gap={0}
								str="When instructed by the HOPS Payment App, tap OK, then tap Payment Received." />

						</Flex>

					</Dialog>
				</Flex>
			</Paper>
		</Flex>
	);

};

export default withBasket(PaymentProcessorHOPSPaymentApp);
