import {Flex, IconButton, String, Table} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import moment from "moment/moment.js";

import withOrders from "Hoc/withOrders.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality";

import RecallIcon from "@mui/icons-material/FindInPage";


/**
 * Recent Orders view
 * 
 * @package HOPS
 * @subpackage Recall
 * @copyright Heritage Operations Processing Limited
 */
const RecentOrdersView = props => {

	const tableFields = [
		{
			label: "Order No.",
			render: i => `L${i.Order}`
		},
		{
			label: "Created",
			render: i => (new moment(i.OrderTimestamp * 1000)).format("DD/MM/YYYY HH:mm:ss")
		},
		{
			label: "Items",
			render: i => i.BasketItems?.length
		},
		{
			label: "Sub Total",
			render: i => Localisation.formatCurrency(i.OrderTotal)
		},
		{
			label: "Change",
			render: i => <String
				color={i.PaymentsChange > 0 ? "error" : undefined}
				str={Localisation.formatCurrency(i.PaymentsChange)} />
		},
		{
			label: "Actions",
			render(i) {
				return (
					<IconButton
						disabled={props.ReducedFunctionality}
						icon={RecallIcon}
						uri={`/search/orders/${i.Order}`} />
				);
			}
		}
	];

	return (
		<Flex>
			<String
				bold={true}
				noFlex={true}
				str="Recent Sales"
				variant="h5" />
			<String
				color="textSecondary"
				noFlex={true}
				str="Sales placed on this device since midnight."
				variant="body1" />
			<Table
				items={props.Orders.History}
				fields={tableFields} />
		</Flex>
	);

};

export default withOrders(withReducedFunctionality(RecentOrdersView));
