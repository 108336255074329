import {Card, Loadable} from "@hps/hops-react";
import {Grid} from "@mui/material";
import PropTypes from "prop-types";
import React, {useState} from "react";

import InventoryPriceInputDialog from "Inventory/InventoryPriceInputDialog.js";

import scss from "./InventoryGridItemBase.module.scss";

/**
 * Base of item cards in inventory grids
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemBase = props => {

	const [priceDialogOpen, setPriceDialogOpen] = useState(false);

	/**
	 * Adding to basket.
	 *
	 * @param {Integer} price
	 * @return {void}
	 */
	const addToBasket = price => {
		props.onAddToBasket?.(price);
		handlePriceDialogClose();
	};

	/**
	 * Add to basket clicked.
	 * 
	 * @return {void}
	 */
	const handleAddToBasket = () => {
		if (props.price !== null) {
			handlePriceDialogClose();
			props.onAddToBasket?.(props.price);
		}
		else setPriceDialogOpen(true);
	};

	/**
	 * Add to basket clicked.
	 * 
	 * @return {void}
	 */
	const handleClick = () => {
		props.onClick?.();
	};

	/**
	 * Render the price input dialog.
	 * 
	 * @return {ReactNode}
	 */
	const renderPriceInputDialog = () => {
		return (
			<InventoryPriceInputDialog
				onClose={handlePriceDialogClose}
				onSubmit={addToBasket}
				open={priceDialogOpen} />
		);
	};


	/**
	 * Price dialog closed.
	 * 
	 * @return {void}
	 */
	const handlePriceDialogClose = () => setPriceDialogOpen(false);


	return (
		<>
			<Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} className={scss.GridItem}>
				<Card
					accentColour={props.accentColour}
					className={`${scss.card} ${props.className}`}
					disabled={props.disabled}
					horizontal={props.horizontal}
					loading={props.loading}
					onClick={props.onAddToBasket ? handleAddToBasket : handleClick}
					outlined={true}
					media={props.mediaElement}
					mediaUri={props.imageSrc}
					gap={0}
					px={0}
					py={0}
					style={props.style}
					value={props.value}>
					<Loadable loading={props.loading}>
						{props.children}
					</Loadable>
				</Card>
			</Grid>
			{(priceDialogOpen && renderPriceInputDialog())}
		</>
	);

};

InventoryGridItemBase.propTypes = {
	horizontal: PropTypes.bool,
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number
};

InventoryGridItemBase.defaultProps = {
	horizontal: true,
	xs: 12,
	sm: 6,
	md: 4,
	lg: 4,
	xl: 3
};

export default InventoryGridItemBase;
