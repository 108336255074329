import {Button, Flex, Hidden, Loader, TextField} from "@hps/hops-react";
import {useCallback, useRef, useState} from "react";

import withBasket from "Hoc/withBasket";
import {handleScan} from "Services/ScannerService.js";

/**
 * Barcode / QR Code Scan Box
 *
 * @package HOPS
 * @subpackage Components
 * @copyright Heritage Operations Processing Limited
 */
const ScanBox = props => {

	const {
		BasketLoading,
		label,
		onAddToBasket,
		placeholder,
		showSubmit,
		variant
	} = props;

	const [textField, setTextField] = useState("");
	const [loading, setLoading] = useState(false);
	const inputRef = useRef(null);

	/**
	 * Submitting.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		setLoading(false);

		await handleScan(textField, onAddToBasket);

		setTextField("");
		inputRef?.current?.focus();

		setLoading(false);

	}, [onAddToBasket, inputRef, textField]);


	/**
	 * Render!
	 */
	return (
		<>
			<form onSubmit={handleSubmit}>
				<Flex
					alignItems="center"
					columnar={true}>
					<TextField
						autoFocus={true}
						disabled={(loading || BasketLoading)}
						inputRef={inputRef}
						label={(label || "Barcode / QR Code")}
						onChange={setTextField}
						placeholder={placeholder}
						required={true}
						value={textField}
						variant={(variant || "outlined")} />

					{BasketLoading && <Loader size={25} />}

					<Hidden hidden={!showSubmit}>
						<Button
							label="Search"
							loading={loading}
							type="submit"
							variant="contained" />
					</Hidden>
				</Flex>
			</form>
		</>
	);

};

export default withBasket(ScanBox);
