import {CheckoutBasketItemDetails, Flex, Hidden, IconButton, String} from "@hps/hops-react";
import {CheckoutBasketItem, Localisation, OrderableTypes, OrderQuestionService, TicketSeatReservationModes} from "@hps/hops-sdk-js";
import Badge from "@mui/material/Badge";
import {makeStyles} from "@mui/styles";
import React from "react";

import SeatReservationChip from "Components/SeatReservationChip.js";
import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";
import withBasket from "Hoc/withBasket.js";
import BasketService from "Services/BasketService.js";

import BasketPaneItemDiscountDetails from "./Discounts/BasketPaneItemDiscountDetails.js";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import QuestionsAnsweredIcon from "@mui/icons-material/DoneAll";
import SeatIcon from "@mui/icons-material/EventSeatOutlined";
import QuestionsIcon from "@mui/icons-material/Feedback";
import SubtractIcon from "@mui/icons-material/Remove";

import scss from "./BasketPaneItem.module.scss";


/**
 * Item in the basket sidebar pane
 * 
 * @package HOPS
 * @subpackage Basket
 * @copyright Heritage Operations Processing Limited
 */
const BasketPaneItem = props => {

	const itemIsAGiftCardInstance = props.item.OrderableType === OrderableTypes.GiftCardInstance;
	const itemIsRelatedToGiftCardInstance = props.relatedItems?.some(item => item.OrderableType === OrderableTypes.GiftCardInstance);

	const disableQtyModification = itemIsAGiftCardInstance || itemIsRelatedToGiftCardInstance;
	const disableRemove = itemIsAGiftCardInstance;

	const hasQuestions = OrderQuestionService.itemHasQuestions(props.BasketQuestions, props.item);
	const questionsCount = OrderQuestionService.getItemQuestionsCount(props.BasketQuestions, props.item);
	const answersCount = OrderQuestionService.getItemAnswersCount(props.BasketQuestions, props.BasketQuestionsAnswers, props.item);

	const optionalNoAnswerAcknowledgementsCount = Object.keys((props.BasketQuestionsAnswersDeclined || {})).filter(k => k.startsWith(props.item.Uuid)).map(k => props.BasketQuestionsAnswersDeclined?.[k]).filter(v => v).length;
	const questionsAnswersCount = (answersCount + optionalNoAnswerAcknowledgementsCount);

	const maxQty = CheckoutBasketItem.getMaxQuantity(props.item);
	const discounts = props.BasketDiscounts?.filter(d => (d.ItemClaim === props.item.Uuid)) ?? [];
	const isRefund = !!(props.item.Quantity < 0);

	const seatReservations = props.BasketItems?.filter(relatedItem => relatedItem.OrderableType === OrderableTypes.SeatReservation &&
		relatedItem.RelatedItemUuid === props.item.Uuid) ?? [];

	const useStyles = makeStyles(theme => {
		return (
			{
				basketItem: {
					backgroundColor: isRefund ? "rgba(255, 0, 0, 0.12)" : undefined,
					borderBottomWidth: 1,
					borderBottomStyle: "solid",
					borderBottomColor: theme.palette.divider
				}
			});
	});

	const classes = useStyles();

	/**
	 * Quantity of this item in the basket changed.
	 *
	 * @param {Integer} newQty
	 * @return {void}
	 */
	const handleQtyChange = async newQty => {
		if (newQty === 0) handleRemove();
		else await BasketService.updateItemQty(props.item, newQty);
	};


	/**
	 * Adding a unit of the item to the quantity in the basket.
	 * 
	 * @return {void}
	 */
	const handleQtyChangeMorePositive = () => {
		handleQtyChange((props.item.Quantity + 1));
	};


	/**
	 * Removing a unit of the item from the quantity in the basket.
	 * 
	 * @return {void}
	 */
	const handleQtyChangeMoreNegative = () => {
		handleQtyChange((props.item.Quantity - 1));
	};


	/**
	 * Removing the item from the basket.
	 *
	 * @return {void}
	 */
	const handleRemove = async () => {
		if (props.BasketItems?.length > 1) {
			await BasketService.removeItems([props.item]);
		}
		else await BasketService.clearItems();
	};


	/**
	 * Seat reservations dialog selected.
	 *
	 * @return {void}
	 */
	const handleSeatReservations = () => {
		dSeatReservationDialog(props.item.Uuid);
	};


	/**
	 * Render the item's order questions icon.
	 *
	 * @return {ReactNode}
	 */
	const renderQuestionsIcon = () => {
		const isAnswered = (questionsAnswersCount >= questionsCount);
		const Icon = (!isAnswered ? QuestionsIcon : QuestionsAnsweredIcon);
		return <Icon color={(!isAnswered ? "error" : "primary")} />;
	};

	return (
		<Flex
			gap={0.5}
			className={classes.basketItem}
			px={1}
			py={0.5}>
			<Flex gap={0.75} columnar={true}>
				<Flex gap={0.25} alignItems="center">
					<String
						centre={true}
						classNameTypography={scss.quantity}
						color="textSecondary"
						noFlex={true}
						str={`${props.item.Quantity}x`} />
					{(hasQuestions && renderQuestionsIcon())}
				</Flex>
				<Flex
					columnar={true}
					justifyContent="space-between"
					width="100%">
					<Flex gap={0.25}>
						<String noFlex={true} str={CheckoutBasketItem.getUiLabel(props.item)} />
						<CheckoutBasketItemDetails item={props.item} />
						{(!!discounts.length && <BasketPaneItemDiscountDetails discounts={discounts} item={props.item} />)}
						{isRefund && <String color="error" str="Refund" variant="subtitle2" />}
					</Flex>
					<String
						classNameTypography={scss.price}
						noFlex={true}
						str={Localisation.formatCurrency(CheckoutBasketItem.getTotalPrice(props.item))} />
				</Flex>
			</Flex>
			{!!seatReservations.length &&
				<Flex
					alignItems="center"
					columnar={true}
					gap={0.5}>
					<String str="Seats" variant="subtitle2" />
					{seatReservations?.map((seat, key) => <SeatReservationChip key={key} seat={seat} />)}
				</Flex>
			}
			<Flex
				alignItems="center"
				columnar={true}>
				<Hidden hidden={props.hideControls || props.hasPayments || false}>
					<Hidden hidden={disableQtyModification}>
						<IconButton
							className={scss.IconButtonCircle}
							disabled={(props.BasketLoading || props.disabled)}
							onClick={!isRefund ? handleQtyChangeMoreNegative : handleQtyChangeMorePositive}
							icon={SubtractIcon} />
						<IconButton
							className={scss.IconButtonCircle}
							disabled={(props.BasketLoading || props.disabled || ((maxQty !== null) && (props.item.Quantity >= maxQty)))}
							onClick={!isRefund ? handleQtyChangeMorePositive : handleQtyChangeMoreNegative}
							icon={AddIcon} />
					</Hidden>
					<Hidden hidden={disableRemove}>
						<IconButton
							className={scss.IconButtonCircle}
							disabled={(props.BasketLoading || props.disabled)}
							onClick={handleRemove}
							icon={DeleteIcon} />
					</Hidden>
					<Hidden hidden={!(TicketSeatReservationModes.allowsCustomerSelection(CheckoutBasketItem.getSeatReservationMode(props.item)) || TicketSeatReservationModes.allowsRailwaySelection(CheckoutBasketItem.getSeatReservationMode(props.item)))}>
						<Badge
							badgeContent={`${props.seatCountAllocated}/${props.seatCount}`}
							color={TicketSeatReservationModes.requiresCustomerSelection(CheckoutBasketItem.getSeatReservationMode(props.item)) ? (props.pendingSeatSelection ? "error" : "success") : "info"}>
							<IconButton
								className={scss.IconButtonCircle}
								color={TicketSeatReservationModes.allowsRailwaySelection(CheckoutBasketItem.getSeatReservationMode(props.item)) ? "warning" : undefined}
								disabled={(props.BasketLoading || props.disabled)}
								onClick={handleSeatReservations}
								icon={SeatIcon} />
						</Badge>
					</Hidden>
				</Hidden>
			</Flex>
			{/*
			<Flex>
				{props.relatedItems?.map((relatedItem, key) => <BasketPaneItem
					item={relatedItem}
					pendingSeatSelection={(seatingValidity[item.Uuid] === false)}
					seatCount={SeatReservationUtils.getSeatCountForBasketItem(item)}
					seatCountAllocated={SeatReservationUtils.getAllocatedSeatsForBasketItem(item, props.BasketItems)?.length}
					key={key} />)}
			</Flex>
			 */}
		</Flex>
	);

};

export default withBasket(BasketPaneItem);
