import {DeviceConnectionModes, DeviceOfflineTypes} from "@hps/hops-react";
import moment from "moment";
import {useEffect, useState} from "react";
import {connect} from "react-redux";

import ConnectivityService from "Services/ConnectivityService";

const withReducedFunctionality = Component => connect(({Basket, Cache, Registration, Ui}) => ({Basket, Cache, Registration, Ui}))(props => {

	const {
		Basket,
		Cache,
		Registration,
		Ui
	} = props;

	const [isAppOffline, setIsAppOffline] = useState(!ConnectivityService.appOnline());
	const [isNetworkOffline, setIsNetworkOffline] = useState(!ConnectivityService.networkOnline());

	const connectivityChanged = (isNetworkOffline !== isAppOffline);
	const basketOfflineUuid = Basket?.Identity?.OfflineBasketUuid;
	const isReducedFunctionality = (isAppOffline || !(basketOfflineUuid === null));

	/**
	 * Hook to watch UI state.
	 * 
	 * The offline detector dispatches a ui/onlineApp
	 * and ui/onlineNetwork when connectivity changes.
	 */
	useEffect(() => {

		setIsAppOffline(!ConnectivityService.appOnline());
		setIsNetworkOffline(!ConnectivityService.networkOnline());

	}, [Ui]);

	const today = new moment().format("YYYY-MM-DD");

	const ticketCacheValid = (
		Cache?.Fares?.Tickets &&
		Cache?.Fares.Tickets.length &&
		Cache?.Fares.ValidDate === today);

	const deviceConnectionMode = Registration?.Device?.ConnectionMode;

	let offlineType = null;

	if (isAppOffline) {

		switch (deviceConnectionMode) {

			case DeviceConnectionModes.Online:
				// Working in online mode, network disconnected
				offlineType = DeviceOfflineTypes.Disconnected;
				break;

			case DeviceConnectionModes.OfflineSync:
				// Working in offline+sync mode
				offlineType = DeviceOfflineTypes.AlwaysOffline;
				break;

			default:
				offlineType = null;
		}

	}

	const alwaysOffline = offlineType === DeviceOfflineTypes.AlwaysOffline;

	return (<Component
		{...props}
		AlwaysOffline={alwaysOffline}
		ConnectivityChanged={connectivityChanged}
		DeviceConnectionMode={deviceConnectionMode}
		Offline={isAppOffline}
		OfflineNetwork={isNetworkOffline}
		OfflineType={offlineType}
		ReducedFunctionality={isReducedFunctionality}
		ticketCacheValid={ticketCacheValid} />);
});

export default withReducedFunctionality;
