import {Flex, String} from "@hps/hops-react";
import {Api, Localisation} from "@hps/hops-sdk-js";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";

import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";

import scss from "./InventoryGridItemGiftCard.module.scss";

/**
 * Inventory grid item
 *
 * An item displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemGiftCard = props => {

	const accentColour = props.backgroundColor === "000000" ? null : props.backgroundColor;

	// Text to show for the balance to issue
	const balanceToIssueText = Localisation.formatCurrency(props.item.Price.Value);

	const label = (props.item.Labels?.Pos || props.item.Name);

	// Text to show if the SKU is available
	let skuAvailableMessage = `${balanceToIssueText}, valid until ${(new moment(props.item.Expiry)).format("DD/MM/YYYY")}`;
	if (props.item.ExpiryMonths) skuAvailableMessage = `${balanceToIssueText}, valid for ${props.item.ExpiryMonths} ${pluralize("Month", props.item.ExpiryMonths)}`;

	const subLabel = (props.skuUnavailable ?
		(props.skuUnavailableMessage || "Not Available") :
		skuAvailableMessage
	);

	const handleAddToBasket = () => {
		props.onAddToBasket?.(props.item);
	};

	return <>
		<InventoryGridItemBase
			accentColour={accentColour}
			disabled={(props.skuUnavailable || props.disabled || props.loading)}
			imageSrc={`${Api.baseURL}${props.imageSrc}`}
			onAddToBasket={handleAddToBasket}>

			<Flex alignItems="center" gap={0}>
				{/* Gift Card Title */}
				<String
					classNameTypography={scss.GiftCardButtonTextLabel}
					color="primary"
					style={{color: accentColour}}
					str={label}
					variant="h6" />

				{/* Price Text */}
				<String
					classNameTypography={scss.GiftCardPriceLabel}
					color={(!props.skuUnavailable ? "textSecondary" : "error")}
					str={subLabel}
					variant="subtitle1" />

			</Flex>
		</InventoryGridItemBase>
	</>;

};

export default InventoryGridItemGiftCard;
