import {Button, Dialog, Flex, Navigator, Paper, String, TableList, withRegistration, withSnackbars} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, OrderVoucherService, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";
import moment from "moment";
import {createRef, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import VoucherCodeInput from "Components/VoucherCodeInput.js";
import withBasket from "Hoc/withBasket";
import BasketService from "Services/BasketService.js";

import scss from "./VoucherLookup.module.scss";

const VoucherLookup = props => {

	const {
		BasketLoading,
		PaymentsBalanceDue,
		Registration,
		snack
	} = props;

	const {BarcodeString} = useParams();

	const formRef = createRef();
	const inputRef = createRef();

	const [code, setCode] = useState(BarcodeString);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [voucher, setVoucher] = useState(null);

	const [voucherBasketValue, setVoucherBasketValue] = useState(0);

	/** Select the input */
	const selectInput = useCallback(() => inputRef?.current?.select?.(), [inputRef]);


	/** Dialog close handler */
	const handleCloseDialog = () => {
		setVoucher(null);
	};


	/** Input change handler */
	const handleInputChange = code => {
		setCode(code);
		setError(false);
		setVoucher(null);
	};


	/** Submission handler */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		if (!formRef?.current?.reportValidity?.()) {
			selectInput();
			return;
		}

		setError(false);
		setLoading(true);

		try {

			const voucher = await OrderVoucherService.getVoucherDetails(code.replaceAll("-", ""));

			if (voucher.Org !== Registration.Org.Id) {
				throw new Error("E_INVALID_ORG");
			}

			setVoucher(voucher);
			setVoucherBasketValue(Math.min(PaymentsBalanceDue, voucher?.AvailableBalance));

		}
		catch (e) {

			setError(e);

			if ((e?.response?.status === 404) || (e?.message === "E_INVALID_ORG")) {
				snack("Unknown voucher code.", "error");
			}
			else if (e?.response?.status === 410) {
				snack("The voucher has expired or been revoked.", "error");
			}
			else if (e?.response?.status === 429) {
				snack("This device is being rate limited. Please wait a while before retrying.", "error");
			}
			else snack(e);

		}

		setLoading(false);

	}, [code, formRef, PaymentsBalanceDue, selectInput, snack, Registration]);


	/** Select the input after errors */
	useEffect(() => {
		if (error) {
			selectInput();
		}
	}, [error, selectInput]);


	/** Pop the dialog if there's a Barcode on first render */
	useEffect(() => {

		if (BarcodeString) handleSubmit();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleApplyToBasket = async () => {

		await BasketService.addPayments([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.HOPSOrderVoucher,
				PaymentProcessorData: voucher,
				PaymentType: PaymentTypes.Voucher,
				TenderedAmount: Number(voucherBasketValue),
				Value: Number(voucherBasketValue)
			})
		]);

		Navigator.navigate("/checkout");

	};

	/** Render! */
	return (
		<Paper>
			<Flex>
				<String
					bold={true}
					str="Voucher Lookup" />
				<form
					onSubmit={handleSubmit}
					ref={formRef}>
					<Flex
						className={scss.formContainer}
						columnar={true}>
						<VoucherCodeInput
							disabled={loading}
							fullWidth={true}
							inputRef={inputRef}
							onChange={handleInputChange}
							onEnter={handleSubmit}
							required={true}
							value={code} />
						<Button
							label="Get Details"
							loading={loading}
							type="submit"
							variant="contained" />
					</Flex>
				</form>
				<Dialog
					onClose={handleCloseDialog}
					open={!!voucher}
					title="Voucher Details">
					<TableList
						data={[
							{
								label: "Issued Balance",
								content: Localisation.formatCurrency((voucher?.IssuedBalance || 0))
							},
							{
								label: "Available Balance",
								content: Localisation.formatCurrency((voucher?.AvailableBalance || 0))
							},
							{
								label: "Expiry Date",
								content: (voucher?.ExpiryDate ? (new moment(voucher.ExpiryDate)).format("DD/MM/YYYY") : "-")
							}
						]} />
					{!!PaymentsBalanceDue && <Button
						disabled={BasketLoading}
						onClick={handleApplyToBasket}
						loading={BasketLoading}
						label={`Apply ${Localisation.formatCurrency(voucherBasketValue)} to Basket`}
						size="large"
						variant="contained" />}
				</Dialog>
			</Flex>
		</Paper>
	);

};

export default withBasket(withRegistration(withSnackbars(VoucherLookup)));
