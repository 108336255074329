import {Banner, Button, Flex, Paper, String} from "@hps/hops-react";
import {CheckoutBasketPayment, Localisation, PaymentProcessorsEnum, PaymentTypes} from "@hps/hops-sdk-js";

import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";


/**
 * Process a payment made with a non-integrated card terminal.
 * 
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @returns JSX
 */
const PaymentProcessorNonIntegratedCard = ({RequestedAmount, onPaymentFailure, onPaymentSuccess}) => {

	const handleOK = async () => {

		dPaymentsInProgress(true);

		/*
		 * Pass the result back to the checkout
		 * Pressing "Card" on PoS, not using the Stripe Terminal integration, type 8 (CardHolderPresent) processor 10 (HOPS)
		 * https://gitlab.heron-web.com/hps/hops/-/issues/686#note_70795
		 */
		await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.HOPS,
				PaymentType: PaymentTypes.CardHolderPresent,
				TenderedAmount: Number(RequestedAmount),
				Value: RequestedAmount
			})
		]);

		dPaymentsInProgress(false);
		dPaymentType(null);

		return;
	};


	const handleCancel = () => {
		return onPaymentFailure({Type: "Card", RequestedAmount, ReceivedAmount: null});
	};


	return (
		<Flex>
			<String bold={true} str="Non-Integrated Card Payment" variant="h5" />
			<Paper>
				<Flex alignItems="center">
					<Banner str="Please take payment from the customer" />

					<String
						str={Localisation.formatCurrency(RequestedAmount)}
						variant="h6" />

					<Button
						label="Payment Received"
						onClick={handleOK}
						variant="contained" />

					<Button
						label="Cancel"
						onClick={handleCancel} />
				</Flex>
			</Paper>
		</Flex>
	);

};

export default withBasket(PaymentProcessorNonIntegratedCard);
