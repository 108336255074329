import {Button, Divider, Flex, Hidden, Navigator, Paper, String} from "@hps/hops-react";
import {CheckoutBasketItem, Localisation, SeatReservationUtils} from "@hps/hops-sdk-js";
import React from "react";

import BasketExpirationTimer from "Basket/BasketExpirationTimer.js";
import BasketPaneItem from "Basket/BasketPaneItem";
import withBasket from "Hoc/withBasket.js";

import PaymentPanelItem from "./PaymentPanelItem";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import scss from "./PaymentPane.module.scss";

/**
 * Renders the payment sidebar pane
 */
const PaymentPane = props => {


	const disabled = props.BasketLoading;

	const renderBasket = () => {

		const items = props.BasketItems?.filter(i => !CheckoutBasketItem.isInternal(i));
		const seatingValidity = SeatReservationUtils.validateBasketSelection(props.BasketItems);

		return (
			<Flex className={scss.containerBasket}>
				<Flex className={scss.containerBasketItems} gap={0}>
					{
						items.map((item, key) => (
							<BasketPaneItem
								disabled={true}
								hideControls={true}
								item={item}
								pendingSeatSelection={(seatingValidity[item.Uuid] === false)}
								key={key} />
						))
					}
					{(!items.length && renderEmpty())}
				</Flex>
				<Flex px={1}>
					<Flex gap={0.5}>
						<Hidden hidden={!props.BasketTotalDiscounts}>
							<Flex
								alignItems="center"
								columnar={true}
								justifyContent="space-between">
								<String
									color="error"
									noFlex={true}
									str="Discounts" />
								<String
									bold={true}
									color="error"
									noFlex={true}
									str={Localisation.formatCurrency(props.BasketTotalDiscounts * -1)} />
							</Flex>
						</Hidden>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								noFlex={true}
								str="Sub Total"
								variant="h6" />
							<String
								bold={true}
								noFlex={true}
								str={Localisation.formatCurrency(props.BasketTotal)}
								variant="h6" />
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		);
	};


	const renderBasketControls = () => {
		return (
			<>
				<Flex
					justifyContent="center"
					columnar={true}
					gap={0.5}>
					<Button
						disabled={disabled}
						label="Back to Inventory"
						onClick={() => Navigator.navigate("/")}
						startIcon={ArrowBackIcon}
						variant="outlined" />
				</Flex>
				<Flex
					alignItems="center"
					columnar={true}
					justifyContent="space-between"
					px={1}>
					<String
						color="textSecondary"
						noFlex={true}
						str="Basket expires in" />
					<BasketExpirationTimer />
				</Flex>
			</>

		);
	};


	const renderPayments = () => {

		const items = props.Payments;

		return (
			<Flex className={scss.containerPayment}>
				<Flex className={scss.containerPaymentItems}>
					{
						items.map((item, key) => (
							<PaymentPanelItem
								disabled={disabled}
								item={item}
								key={key} />
						))
					}
					{(!items.length && renderEmpty())}
				</Flex>
				<Flex>
					<Flex gap={0.5}>
						<Divider/>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								noFlex={true}
								str="Received" />
							<String
								bold={true}
								noFlex={true}
								str={Localisation.formatCurrency(props.PaymentsReceived)} />
						</Flex>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								color={!props.PaymentsIsSettled ? "error" : undefined}
								noFlex={true}
								str="Balance Due"
								variant="h6" />
							<String
								bold={true}
								color={!props.PaymentsIsSettled ? "error" : undefined}
								noFlex={true}
								str={Localisation.formatCurrency(props.PaymentsBalanceDue)}
								variant="h6" />
						</Flex>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								color={props.PaymentsChangeIsDue ? "error" : undefined}
								noFlex={true}
								str="Change" />
							<String
								bold={true}
								color={props.PaymentsChangeIsDue ? "error" : undefined}
								noFlex={true}
								str={Localisation.formatCurrency(props.PaymentsChange)} />
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		);
	};


	const renderEmpty = () => {
		return (
			<Flex
				alignItems="center"
				justifyContent="center"
				flexGrow={1}>
				<String
					color="textSecondary"
					str="No Payment Taken"
					variant="h6" />
			</Flex>
		);
	};


	return <>
		<Flex className={scss.wrapper}>
			<Paper variant="outlined" px={0}>
				<Flex className={scss.container} gap={0}>
					<Flex>
						<Flex gap={0.5}>
							<Flex
								alignItems="center"
								gap={0.5}>
								<String
									bold={true}
									centre={true}
									noFlex={true}
									str="Basket"
									variant="h6" />
							</Flex>
							{renderBasketControls()}
							{renderBasket()}
						</Flex>
					</Flex>
				</Flex>
			</Paper>
			<Paper variant="outlined">
				<Flex className={scss.container} gap={1}>
					<Flex
						alignItems="center"
						gap={0.5}>
						<String
							bold={true}
							centre={true}
							noFlex={true}
							str="Payment"
							variant="h6" />
					</Flex>
					{renderPayments()}
				</Flex>
			</Paper>
		</Flex>
	</>;

};

export default withBasket(PaymentPane);
