import {Banner, BasicView, Flex, Loadable, Navigator, Store, String, TableList, useData} from "@hps/hops-react";
import {Api, DeviceTypes, Localisation, OrderStatuses, PaymentStatuses} from "@hps/hops-sdk-js";
import {Typography} from "@mui/material";
import {useCallback, useMemo} from "react";
import Helmet from "react-helmet";
import {Link, useLocation, useParams} from "react-router-dom";

import OrderPaymentRefundsTable from "./OrderPaymentRefundsTable.js";
import NewRefundSection from "./SettleOrderPaymentViewNewRefundSection.js";

import OrderPaymentForm from "../Payments/OrderPaymentForm.js";


const OrderRefundViewModes = {
	refund: {
		title: "Refund Options"
	},
	universal: {
		title: "Settle Payment"
	}
};

const SettleOrderPaymentView = props => {

	const {OrderId} = useParams();

	const viewModeId = useLocation().pathname.split("/").pop();
	const viewMode = (OrderRefundViewModes[viewModeId] || OrderRefundViewModes.universal);

	const orderFetch = useData(useCallback(() => {

		const deviceType = Store.getState().Device?.Type;
		const baseUrl = deviceType === DeviceTypes.POS ? "/api/pos" : "/api";

		return Api.call({
			url: `${baseUrl}/orders/${OrderId}`,
			params: {
				ExposeLinkedOrderPayments: 1,
				ExposeOrderVoucherDetails: 1
			}
		}).then(({data}) => data);
	}, [OrderId]));


	const orderTotals = useMemo(() => {

		const orderData = orderFetch.data;

		if (orderData) {

			const SaleValue = orderFetch.data.Items.reduce((a, b) => {
				return (a + ((b.PriceExVat + b.PriceVat) * b.Quantity));
			}, 0);

			const PaymentsThisOrder = orderFetch.data.Payments.filter(p => {
				return (p.Order === orderFetch.data.Id);
			});

			const PaymentsComplete = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Complete);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			const PaymentsPending = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Pending);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			return {
				AmountOwing: (SaleValue - PaymentsComplete - PaymentsPending),
				SaleValue,
				PaymentsComplete,
				PaymentsPending
			};

		}
		else return undefined;

	}, [orderFetch.data]);


	const tableData = useMemo(() => {

		const amountOwing = (orderTotals?.AmountOwing !== 0);

		return [
			{
				label: "Order Value",
				content: Localisation.formatCurrency(orderTotals?.SaleValue)
			},
			{
				label: "Complete Payments",
				content: Localisation.formatCurrency(orderTotals?.PaymentsComplete)
			},
			{
				label: "Pending Payments",
				content: Localisation.formatCurrency(orderTotals?.PaymentsPending)
			},
			{
				label: "Amount Owing",
				content: (
					<String
						bold={true}
						color={(amountOwing ? "error" : undefined)}
						str={Localisation.formatCurrency(orderTotals?.AmountOwing)}
						style={{color: amountOwing ? undefined : "green"}} />
				)

			}
		];
	}, [orderTotals]);


	const handlePaymentRefunded = useCallback(() => {
		if (props.backLink) Navigator.navigate(props.backLink);
	}, [props.backLink]);


	const title = `Order L${OrderId}: ${(viewMode.title)}`;
	const refundAmountOwing = ((orderTotals?.AmountOwing < 0) ? Math.abs(orderTotals?.AmountOwing) : 0);

	const isPaymentOwing = (orderTotals?.AmountOwing > 0);

	/**
	 * We can't add payments to a recalled rejected/cancelled order.
	 * This might have undesired effects on item claims which
	 * were previously presumed to be unavailable because
	 * this order was in a rejected state.
	 * 
	 * You could end up with, e.g. two claims for seat reservations
	 * or two claims for a redeemed Gift Card.
	 */
	const orderStatusAllowsPaymentsOnRecall = [
		OrderStatuses.Complete, // deposit
		OrderStatuses.Provisional
	].includes(orderFetch?.data?.Status.Id);

	const canTakePayment = orderStatusAllowsPaymentsOnRecall && isPaymentOwing && (orderFetch?.data?.TaxDate === null);

	return (
		<BasicView
			backLink={props.backLink}
			backLinkLabel={props.backLinkLabel}
			title={title}>
			<Helmet
				title={`${title} | HOPS`} />
			<Loadable
				error={orderFetch.error}
				loading={orderFetch.loading}
				onRetry={orderFetch.fetch}>
				<Flex gap={2} mt={1}>
					{
						orderFetch.data?.LinkedOrder &&
							<Banner
								title="Credit note order">
								<Typography>
									This order is a credit note issued against the original order <Link to={`/search/orders/${orderFetch.data.LinkedOrder.Id}`}>L{orderFetch.data.LinkedOrder.Id}</Link>.
								</Typography>
							</Banner>
					}
					<Flex columnar={true}>
						<Flex>
							<String
								str="Payment Summary"
								noFlex={true}
								variant="h6" />
							<TableList
								data={tableData}
								labelsWidth="17rem"
								size="small"
								valuesWidth="15rem" />
						</Flex>
						{
							(viewModeId === "payment") &&
							(canTakePayment ?
								<OrderPaymentForm
									backLink={props.backLink}
									order={orderFetch.data}
									RequestedAmount={(Math.max(orderTotals?.AmountOwing, 0))} /> :
								<String
									color="error"
									str="This order's status does not allow additional payments to be taken." />)
						}
						{
							(viewModeId === "refund") &&
							<Flex>
								<NewRefundSection
									amountOwing={refundAmountOwing}
									onRefunded={handlePaymentRefunded}
									targetOrderId={orderFetch.data?.Id} />
								<OrderPaymentRefundsTable
									amountOwing={refundAmountOwing}
									onRefunded={handlePaymentRefunded}
									orderVouchers={orderFetch.data?.OrderVouchers}
									payments={orderFetch.data?.Payments}
									linkedOrderId={orderFetch.data?.LinkedOrder?.Id}
									targetOrderId={orderFetch.data?.Id} />
							</Flex>
						}
					</Flex>

				</Flex>
			</Loadable>
		</BasicView>
	);

};

export default SettleOrderPaymentView;
