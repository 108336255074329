import {Store} from "@hps/hops-react";
import {CheckoutBasketItem, CheckoutBasketItemCollection, CheckoutBasketPayment, Localisation, OrderableTypes, OrderVoucherService} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";
import moment from "moment";
import receiptline from "receiptline";
import {sprintf} from "sprintf-js";
import wrap from "word-wrap";

/**
 * Receipt utilities
 *
 * @package HOPS
 * @subpackage Receipts
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ReceiptUtils {

	/**
	 * Convert a Receiptline-compatible Markdown string to a receipt SVG.
	 *
	 * @param {String} Receiptline-compatible Markdown string
	 * @param {Integer} cpl optional Characters per line
	 * @return {String} SVG element source
	 */
	static getSvg(receipt, cpl=undefined) {
		return receiptline.transform(
			receipt,
			{
				cpl: (cpl || this.widths.line),		// Characters Per Line
				spacing: true
			}
		);
	}


	/**
	 * Create a receipt from a checkout outcome object and the 
	 * basket items that comprised the order.
	 *
	 * @param {Object} CheckoutOutcome Checkout outcome API object
	 * @param {Array<Object>} OrderBasketItems `CheckoutBasketItem`-like objects
	 * @param {Array<Object>} OrderBasketDiscounts Basket item discount objects
	 * @param {Integer} cpl optional Characters per line
	 * @return {String} Receiptline-compatible Markdown string
	 */
	static createReceipt(CheckoutOutcome, cpl=undefined, reprint=false, kickCashDrawer=false) {

		let receiptItems,
			uniqueDiscountCodes,
			discountsSummary,
			vatSummary,
			giftCardItems,
			voucherSaleItems,
			voucherRedemptionSummary;

		/**
		 * Get the active org
		 */
		const org = Store.getState().Registration.Org;

		// Build the org's trade name
		const orgName = org.NameTrade || org.Name;


		/**
		 * Build the org's address text
		 */
		const orgAddress = [
			org.Address?.Address,
			org.Address?.City,
			org.Address?.Postcode
		].filter(a => a).map(a => a.trim()).join("\n");

		/**
		 * Get a `CheckoutBasketItemCollection` with our items
		 */
		const itemsCollection = new CheckoutBasketItemCollection(CheckoutOutcome.BasketItems);

		/**
		 * All basket items which have not received special treatment above
		 *
		 * They'll each be shown on the receipt with text obtained by 
		 * calling `CheckoutBasketItem.getReceiptText()`.
		 */
		try {
			receiptItems = itemsCollection.getSortedByUiOrder().filter(({OrderableType}) => {
				return ![
					OrderableTypes.SeatReservation,
					OrderableTypes.VoucherSale,
					OrderableTypes.GiftCardInventory
				].includes(OrderableType);
			});
		}
		catch (e) {
			receiptItems = [];
			Sentry.captureException(e);
		}


		/**
		 * Discover the unique discount codes in the basket
		 */
		try {
			uniqueDiscountCodes = CheckoutOutcome.Discounts.map(d => d.Discount.Code).filter((v, i, a) => (a.indexOf(v) === i));
		}
		catch (e) {
			uniqueDiscountCodes = [];
			Sentry.captureException(e);
		}

		/**
		 * Iterate over our discount codes to produce our summary output
		 */
		try {
			discountsSummary = uniqueDiscountCodes.map(Code => {

				const TotalAmount = CheckoutOutcome.Discounts.filter(d => {
					return (d.Discount.Code === Code);
				}).reduce((v, d) => {

					const itemClaim = CheckoutOutcome.BasketItems.find(i => (i.Uuid === d.ItemClaim));

					if (!itemClaim) {
						Sentry.captureMessage(`Item claim "${d.ItemClaim}" unretrieved!`);
						return v;
					}

					const discountQty = Math.min(d.DiscountQty, itemClaim.Quantity);

					return (v + (d.Discount.Amount * discountQty));

				}, 0);

				return {
					Code,
					TotalAmount
				};

			});
		}
		catch (e) {
			discountsSummary = [];
			Sentry.captureException(e);
		}

		/**
		 * Handle the "VAT Summary" lines to include on the receipt
		 */
		try {
			vatSummary = (CheckoutOutcome?.VatSummary || []);
		}
		catch (e) {
			vatSummary = [];
			Sentry.captureException(e);
		}

		/**
		 * Create the receipt items for gift cards
		 */
		try {
			giftCardItems = Object.keys((CheckoutOutcome.CheckoutData?.PurchasedGiftCards || {})).reduce((items, itemClaimUuid) => {

				const itemClaim = CheckoutOutcome.BasketItems.find(i => (i.Uuid === itemClaimUuid));

				if (!itemClaim) {
					Sentry.captureMessage(`Item claim "${itemClaimUuid}" unretrieved!`);
					return items;
				}

				const discounts = CheckoutOutcome.Discounts.filter(d => (d.ItemClaim === itemClaimUuid));

				return [
					...items,
					...(CheckoutOutcome.CheckoutData?.PurchasedGiftCards[itemClaimUuid] || []).map((giftcard, i) => {

						const discountsThisGiftCard = [];

						/**
						 * We split gift card sales to have individual receipt 
						 * lines for each unit in the quantity, so each code 
						 * is displayed individually.
						 *
						 * Consequently, this unit needs to show only the 
						 * discounts that were actually applied to it. We 
						 * also have to adjust each discount object so it 
						 * appears to only to apply to one unit each time.
						 */
						for (const discount of discounts) {
							if (i < discount.DiscountQty) {
								discountsThisGiftCard.push({
									...discount,
									DiscountQty: 1
								});
							}
						}

						return {
							Description: sprintf(
								"%s Gift Card\n~~Code:\n~~~~%s\n~~Valid until: %s",
								giftcard.Inventory.Name,
								giftcard.Code.match(/.{1,5}/g).join("-"),
								(new moment(giftcard.ExpiryDate)).format("DD/MM/YYYY")
							),
							ItemClaimUuid: itemClaimUuid,
							Discounts: discountsThisGiftCard,
							Price: itemClaim.Price,
							VatProportion: itemClaim.VatProportion,
							VatRate: itemClaim.VatRate
						};

					})
				];

			}, []).flat();
		}
		catch (e) {
			giftCardItems = [];
			Sentry.captureException(e);
		}

		/**
		 * Create the receipt items for vouchers
		 */
		try {
			voucherSaleItems = Object.keys((CheckoutOutcome.CheckoutData?.PurchasedOrderVouchers || {})).reduce((items, itemClaimUuid) => {

				const itemClaim = CheckoutOutcome.BasketItems.find(i => (i.Uuid === itemClaimUuid));

				if (!itemClaim) {
					Sentry.captureMessage(`Item claim "${itemClaimUuid}" unretrieved!`);
					return items;
				}

				const discounts = CheckoutOutcome.Discounts.filter(d => (d.ItemClaim === itemClaimUuid));

				return [
					...items,
					...(CheckoutOutcome.CheckoutData?.PurchasedOrderVouchers[itemClaimUuid] || []).map((voucher, i) => {

						const discountsThisVoucher = [];

						/**
						 * We split voucher sales to have individual receipt 
						 * lines for each unit in the quantity, so each code 
						 * is displayed individually.
						 *
						 * Consequently, this unit needs to show only the 
						 * discounts that were actually applied to it. We 
						 * also have to adjust each discount object so it 
						 * appears to only to apply to one unit each time.
						 */
						for (const discount of discounts) {
							if (i < discount.DiscountQty) {
								discountsThisVoucher.push({
									...discount,
									DiscountQty: 1
								});
							}
						}

						return {
							Description: sprintf(
								"%s Voucher\n~~Code:\n~~~~%s\n~~Valid until: %s%s",
								Localisation.formatCurrency(voucher.Balance),
								voucher.Code.match(/.{1,4}/g).join("-"),
								(new moment(voucher.ExpiryDate)).format("DD/MM/YYYY"),
								(voucher.AvailableBalance !== null ? `\n~~Available: ${Localisation.formatCurrency(voucher.AvailableBalance)}` : `\nCannot determine available balance.`)
							),
							ItemClaimUuid: itemClaimUuid,
							Discounts: discountsThisVoucher,
							Price: voucher.Balance,
							VatProportion: itemClaim.VatProportion,
							VatRate: itemClaim.VatRate
						};

					})
				];

			}, []).flat();
		}
		catch (e) {
			voucherSaleItems = [];
			Sentry.captureException(e);
		}

		/**
		 * Voucher redemption summary details
		 */
		try {

			/**
			 * This is the modern (post-hops#803) structure
			 */
			if (CheckoutOutcome.RedeemedOrderVouchers) {
				voucherRedemptionSummary = CheckoutOutcome.RedeemedOrderVouchers;
			}

			/**
			 * DEPRECATED structure
			 * 
			 * Here for back-compat only while #803 is developed
			 */
			else if (CheckoutOutcome.CheckoutData?.RedeemedOrderVouchers) {
				voucherRedemptionSummary = Object.keys((CheckoutOutcome.CheckoutData?.RedeemedOrderVouchers || {})).map(Code => {
					return {
						Code,
						...CheckoutOutcome.CheckoutData.RedeemedOrderVouchers[Code]
					};
				});
			}

			/**
			 * No vouchers used
			 */
			else voucherRedemptionSummary = [];

		}
		catch (e) {
			voucherRedemptionSummary = [];
			Sentry.captureException(e);
		}

		/**
		 * Total value of all discounts in the basket
		 */
		const totalDiscountAmount = discountsSummary.reduce((a, b) => (a + b.TotalAmount), 0);

		/**
		 * Create the actual receipt now
		 */
		return [
			`"${orgName}"`,
			(CheckoutOutcome.PosDevice?.Offline ? "Offline Transaction" : `Order No: L${CheckoutOutcome.Order}`),
			`Till: ${CheckoutOutcome.PosDevice?.Id} ${CheckoutOutcome.PosDevice?.Name}`,
			`${(new moment((CheckoutOutcome.OrderTimestamp * 1000))).format("ddd DD/MM/YYYY HH:mm")}`,
			(reprint ? `"*** Duplicate Receipt ***"` : undefined),
			(reprint ? `Re-printed ${(new moment()).format("ddd DD/MM/YYYY HH:mm")}` : undefined),
			``,
			`{width:*,${this.widths.amount}}`,
			`|_Item_ | _Amount_`,
			...(receiptItems || []).map(i => {
				return this.createBasketItemReceiptLine(
					CheckoutBasketItem.getReceiptText(i, CheckoutOutcome.BasketItems),
					i.Quantity,
					i.Price,
					(i.OrderableType !== OrderableTypes.TicketTravel),
					cpl,
					CheckoutOutcome.Discounts.filter(d => (d.ItemClaim === i.Uuid))
				);
			}).flat(),
			...(giftCardItems || []).map(gc => {
				return this.createBasketItemReceiptLine(
					gc.Description,
					1,
					gc.Price,
					true,
					cpl,
					gc.Discounts
				);
			}).flat(),
			...(voucherSaleItems || []).map(v => {
				return this.createBasketItemReceiptLine(
					v.Description,
					1,
					v.Price,
					true,
					cpl,
					v.Discounts
				);
			}).flat(),
			`{width:auto}`,
			`---`,
			`"TOTAL | "${Localisation.formatCurrency(Math.max(((CheckoutOutcome.BasketItems || []).reduce((a, b) => (a + CheckoutBasketItem.getTotalPrice(b)), 0) - totalDiscountAmount), 0))}`,
			``,
			(CheckoutOutcome.wasDepositCheckout ? `"DEPOSITS: ` : undefined),
			...(CheckoutOutcome.Payments || []).map(payment => {
				return [this.createBasketPaymentReceiptLine(
					CheckoutBasketPayment.getUiLabel(payment),
					payment.TenderedAmount,
					cpl
				),
				CheckoutBasketPayment.getReceiptText(payment)];
			}).flat(),
			(CheckoutOutcome.PaymentsChangeIsDue ? `"YOUR CHANGE | "${Localisation.formatCurrency(CheckoutOutcome.PaymentsChange)}` : undefined),
			``,
			(discountsSummary?.length ? `|_DISCOUNT SUMMARY_` : undefined),
			...discountsSummary.map(d => `|${d.Code}: ${Localisation.formatCurrency(d.TotalAmount)}`),
			(discountsSummary?.length ? `` : undefined),
			(vatSummary?.length ? `|_VAT SUMMARY_` : undefined),
			...vatSummary.map(v => `|${Localisation.formatCurrency(v.PriceAmount)} @ ${v.VatRate}% VAT = ${Localisation.formatCurrency(v.VatAmount)}`),
			(vatSummary?.length ? `|Total VAT: ${Localisation.formatCurrency(vatSummary.reduce((a, b) => (a + b.VatAmount), 0))}` : undefined),
			(vatSummary?.length ? `` : undefined),
			(voucherRedemptionSummary?.length ? `|_GIFT VOUCHER SUMMARY_` : undefined),
			...voucherRedemptionSummary.map(v => `|* ${OrderVoucherService.getVoucherCodeDisplayString(v.Code)}\\n  Valid until: ${(new moment(v.ExpiryDate)).format("DD/MM/YYYY")}\\n  Remaining Balance: ${Localisation.formatCurrency(v.Balances.Current)}`),
			(voucherRedemptionSummary?.length ? `` : undefined),
			`${orgName}`,
			(orgAddress || undefined),
			(org.PhoneNo || undefined),
			(org.VatNo ? `\nVAT Reg. No.: ${org.VatNo}` : undefined),
			``,
			`.`,
			// Kick cash drawer through serial commands (https://poshelp.robotill.com/OpenDrawerCodes.aspx)
			(kickCashDrawer ? `{command:\x07}` : undefined), // code 07
			(kickCashDrawer ? `{command:\x1b\x70\x00\x19\xfa}` : undefined) // code 27,112,0,25,250
		].filter(i => (i !== undefined)).join("\n");

	}


	/**
	 * Create a Receiptline-compatible Markdown receipt line item string.
	 *
	 * @param {String} description
	 * @param {Integer} quantity
	 * @param {Integer} price
	 * @param {Boolean} qtyInDescription optional
	 * @param {Integer} cpl optional Characters per line
	 * @param {Array} discounts optional Discounts applied to this item
	 * @return {String}
	 */
	static createBasketItemReceiptLine(description, quantity, price, qtyInDescription=true, cpl=undefined, discounts=[]) {

		let lines;

		try {

			lines = [
				`|${this.prepareItemDescription(description, (qtyInDescription ? quantity : undefined), cpl)} | ${Localisation.formatCurrency((price * quantity))}`
			];

			discounts.forEach(discount => {
				const discountQty = Math.min(quantity, discount.DiscountQty);
				lines.push(`|${this.prepareItemDescription(`Discount: ${discount.Discount.Code}`, ((discountQty !== quantity) ? discountQty : undefined), cpl, true)} | ${Localisation.formatCurrency(-(discount.Discount.Amount * discountQty))}`);
			});

		}
		catch (e) {
			lines = ["-ERROR PRINTING ITEM DATA-"];
			Sentry.captureException(e);
		}

		return lines;

	}


	/**
	 * Create a Receiptline-compatible Markdown receipt line payment string.
	 *
	 * @param {String} description
	 * @param {Integer} value
	 * @param {Integer} cpl optional Characters per line
	 * @return {String}
	 */
	static createBasketPaymentReceiptLine(description, value, cpl=undefined) {

		let lines;

		try {

			lines = [
				`|${this.prepareItemDescription(description, undefined, cpl)} | ${Localisation.formatCurrency(value)}`
			];

		}
		catch (e) {
			lines = ["-ERROR PRINTING PAYMENT DATA-"];
			Sentry.captureException(e);
		}

		return lines;

	}


	/**
	 * Prepare an item's description text string to present in a receipt.
	 *
	 * Reformats it for cleaner rendering within Receiptline Markdown.
	 *
	 * @param {String} str
	 * @param {Integer} qty optional Item quantity
	 * @param {Integer} cpl optional Characters per line
	 * @param {Boolean} sub optional Render as a sub-item (`false`)
	 * @return {String}
	 */
	static prepareItemDescription(str, qty=undefined, cpl=undefined, sub=false) {

		/**
		 * The maximum width of description lines is the line 
		 * width, minus the gutter widths, minus the widths of 
		 * the other columns
		 */
		const lineLength = (
			((cpl || this.widths.line) - (this.widths.gutter * 2)) -
			this.widths.amount
		);

		/**
		 * Receiptline doesn't automatically do word-wrap so we do it now
		 * to stop it breaking on arbitrary characters in the text!
		 */
		return str?.split("\n").map((str, i) => {

			if (i === 0) {

				if (!sub) str = `${str.toUpperCase()}`;
				else str = `~~${str}`;

				if (qty && (qty > 1)) {
					str += ` (x${qty})`;
				}

			}

			return wrap(
				str?.trimEnd(),
				{

					/**
					 * Wrap after this many characters
					 *
					 * We subtract `2` to cater for the leading 
					 * padding applied in `newline` below.
					 */
					width: (lineLength - 2),

					/**
					 * `word-wrap` automatically indents each line 
					 * by two spaces normally which breaks everything
					 */
					indent: "",

					/**
					 * `\n` is special to Receiptline so we need to escape 
					 * the backslash so it renders as a regular newline
					 */
					newline: "\\n  "

				}
			);

		}).join("\\n");

	}


	/**
	 * Character widths config
	 *
	 * @type {Object}
	 */
	static widths = {

		/**
		 * Max characters on a line
		 *
		 * @type {Integer}
		 */
		line: 48,

		/**
		 * Width of gutters between columns
		 *
		 * (This seems to be hardcoded into Receiptline.)
		 * 
		 * @type {Integer}
		 */
		gutter: 1,

		/**
		 * Character width of the per-item "Amount" column
		 *
		 * @type {Integer}
		 */
		amount: 9 // £9,999.99

	};

}

export default ReceiptUtils;
