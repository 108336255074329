import "./index.scss";
import {Store} from "@hps/hops-react";
import {Api, MinorCurrencyRatio} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App/App.js";
import worker from "./worker.js";


/**
 * Activate Sentry error reporting integration
 */
if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_APP
	});
}

/**
 * Development override for HOPS API base URL
 */
if (process.env.REACT_APP_HOPS_API_BASE_URL) {
	Api.baseURL = process.env.REACT_APP_HOPS_API_BASE_URL;
}

/**
 * Configure the API client to send the PoS device token header when set
 */
Api.getHeaders = request => {

	const headers = {};

	const token = Store.getState()?.Registration?.Device?.RegistrationToken;
	if (token && request.url.startsWith("/api")) headers["Hops-Pos-Device-Token"] = token;

	return headers;

};

/**
 * Localisation
 */
Api.locale = "en-gb";
Api.currencyUnit = "GBP";
Api.minorUnitDivisor = MinorCurrencyRatio.Two;

/**
 * Register the service worker
 */
worker();

/**
 * Render the React app!
 */
ReactDOM.render(<App />, document.getElementById("react"));
