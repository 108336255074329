import {Flex, GiftCardLookupUi, Paper, String} from "@hps/hops-react";

const GiftCardLookup = props => {

	const {inputRef, loading, onChange, onSubmit, value} = props;

	return (
		<Paper>
			<Flex>
				<String
					bold={true}
					str="Gift Card Lookup" />
				<GiftCardLookupUi
					helperText=""
					inputRef={inputRef}
					label="Gift Card Code"
					loading={loading}
					onChange={onChange}
					onSubmit={onSubmit}
					value={value} />
			</Flex>
		</Paper>
	);

};

export default GiftCardLookup;
