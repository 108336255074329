import {Animate, Drawer, Fab, Flex, Hidden, Paper, String, UnhandledErrorView, withMobile} from "@hps/hops-react";
import {CheckoutBasketItem, Localisation} from "@hps/hops-sdk-js";
import {Badge, Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as Sentry from "@sentry/react";
import React from "react";
import {useState} from "react";

import BasketPane from "Basket/BasketPane.js";
import withBasket from "Hoc/withBasket";
import InventoryBrowser from "Inventory/InventoryBrowser.js";

import BasketInProgress from "@mui/icons-material/HourglassBottom";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";
import Warning from "@mui/icons-material/Warning";

import scss from "./OrderView.module.scss";

/**
 * Order view
 * 
 * The main view used to pick products and add them to the order basket.
 * 
 * @package HOPS
 * @subpackage Orderv
 * @copyright Heritage Operations Processing Limited
 */


const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	fab: {
		position: "absolute",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 900 // So we are behind the drawer
	}
}));

const OrderView = props => {

	const classes = useStyles();

	const [basketDrawerOpen, setBasketDrawerOpen] = useState(false);

	const basketIcon = () => {
		if (props.BasketLoading) return <Animate animation="spin" component={BasketInProgress} componentProps={{fontSize: "large"}} />;
		else return <ShoppingBasket fontSize="large" />;
	};

	const handleDrawerClose = () => {
		setBasketDrawerOpen(false);
	};

	const handleDrawerToggle = () => {
		setBasketDrawerOpen(!basketDrawerOpen);
	};

	const basketItems = props.BasketItems?.filter(i => !CheckoutBasketItem.isInternal(i));

	return (
		<main>
			<Box
				className={scss.root}
				sx={{width: {lg: `calc(100% - ${drawerWidth}px)`}}}>
				<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
					<Hidden hidden={props.hasPayments} >
						<InventoryBrowser />
					</Hidden>
					<Hidden hidden={props.hasNoPayments}>
						<Paper>
							<Flex gap={1.5}>
								<Flex alignItems="center" gap={1}>
									<Warning
										color="error"
										fontSize="large" />

									<String
										bold={true}
										str="Payment in Progress"
										variant="h4" />

									<String str="You can't modify the basket contents because payments are in progress." />
								</Flex>
							</Flex>
						</Paper>
					</Hidden>
				</Sentry.ErrorBoundary>
			</Box>
			<Drawer
				anchor="right"
				open={basketDrawerOpen}
				onClose={handleDrawerClose}>
				<BasketPane/>
			</Drawer>
			<Fab
				color="primary"
				className={classes.fab}
				onClick={handleDrawerToggle}
				size="large"
				variant="extended">
				<Flex columnar={true} gap={2}>
					<Badge badgeContent={basketItems.length} color="error">
						{basketIcon()}
					</Badge>
					{basketItems.length > 0 && <String
						color="inherit"
						str={Localisation.formatCurrency(props.BasketTotal)}
						variant="h6" />
					}
				</Flex>
			</Fab>
		</main>
	);

};

export default withBasket(withMobile(OrderView));
