import {String} from "@hps/hops-react";
import React from "react";

import InventoryGridItemBase from "./InventoryGridItemBase.js";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";


/**
 * Inventory grid folder
 *
 * An folder displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemFolder = props => {

	const handleFolderClicked = () => {
		props.onChangeFolder?.(props.item);
	};


	const renderFolderIcon = () => {
		return <>
			{props.tileIcon ? props.tileIcon : <FolderOpenIcon sx={{fontSize: 45}} />}
		</>;
	};


	return <InventoryGridItemBase
		accentColour={props.accentColour || "#f00"}
		disabled={(props.disabled || props.loading)}
		mediaElement={renderFolderIcon()}
		onClick={handleFolderClicked}>
		<String
			color="primary"
			str={props.label}
			variant="h6" />
	</InventoryGridItemBase>;
};

export default InventoryGridItemFolder;
