import {Navigator} from "@hps/hops-react";
import {OrderStatuses} from "@hps/hops-sdk-js";
import {Chip, Tooltip} from "@mui/material";

import scss from "./OrderStatusChip.module.scss";

const OrderStatusChip = props => {

	let orderStatusClassName = scss.unknown;
	let statusName = "Unknown";

	const status = (props.Status?.Id ?? props.Status);

	switch (status) {

		case OrderStatuses.Rejected:
			orderStatusClassName = scss.rejected;
			statusName = "Rejected";
			break;

		case OrderStatuses.Provisional:
			orderStatusClassName = scss.provisional;
			statusName = "Provisional";
			break;

		case OrderStatuses.Complete:
			orderStatusClassName = scss.definite;
			statusName = "Complete";
			break;

		case OrderStatuses.Cancelled:
			orderStatusClassName = scss.cancelled;
			statusName = "Cancelled";
			break;

		default:
			orderStatusClassName = scss.unknown;
			statusName = "Unknown";
	}

	const handleClick = () => {
		Navigator.navigate(props.uri);
	};

	return (
		<>
			<Tooltip title={statusName}>
				<Chip
					avatar={<div className={`${scss.chip} ${orderStatusClassName}`}></div>}
					label={`L${props.Id}`}
					onClick={props.uri && handleClick}
				/>
			</Tooltip>
		</>
	);

};

export default OrderStatusChip;
