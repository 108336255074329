import {Flex, Navigator, Paper, SnackbarService} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import {useCallback, useState} from "react";

import PaymentHandler from "Payment/PaymentHandler";
import PaymentMethodButtons from "Payment/PaymentMethodButtons";
import OrderService from "Services/OrderService";

import scss from "./OrderPaymentForm.module.scss";

const OrderPaymentForm = ({backLink, order, RequestedAmount=null}) => {

	const [paymentType, setPaymentType] = useState(null);

	const handlePaymentSuccess = useCallback(async payments => {

		await OrderService.recordPayments(order.Id, payments);

		payments.forEach(payment => {
			if (payment.PaymentProcessorData?.Change) {
				SnackbarService.snack(
					`Customer Change: ${Localisation.formatCurrency(payment.PaymentProcessorData?.Change)}`,
					"info",
					{
						persist: true,
						withCloseButton: true
					}
				);
			}
		});

		if (backLink) Navigator.navigate(backLink);

	}, [backLink, order]);


	const handlePaymentFailure = useCallback(() => {
		if (backLink) Navigator.navigate(backLink);
	}, [backLink]);

	return (
		<Flex alignItems="center" className={scss.paymentSection}>
			{paymentType ?
				<PaymentHandler
					onPaymentFailure={handlePaymentFailure}
					onPaymentSuccess={handlePaymentSuccess}
					order={order}
					PaymentType={paymentType}
					RequestedAmount={(RequestedAmount || 0)} /> :
				<Paper>
					<PaymentMethodButtons
						balance={RequestedAmount}
						columnar={true}
						onChangePaymentType={setPaymentType}
						showPaymentMethods={true} />
				</Paper>
			}
		</Flex>
	);
};

export default OrderPaymentForm;
