import {Card, Flex} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import React, {useEffect, useState} from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import scss from "./UiClock.module.scss";

/**
 * Clock component
 *
 * @package HOPS
 * @subpackage Ui
 * @copyright Heritage Operations Processing Limited
 */
const UiClock = () => {

	const [currentTime, setCurrentTime] = useState(Localisation.formatDateTime(new Date(), Localisation.DateTimeFormats.LongTime));

	const UpdateTime = () => {
		const time = Localisation.formatDateTime(new Date(), Localisation.DateTimeFormats.LongTime);
		setCurrentTime(time);
	};

	useEffect(() => {

		const clockTick = setInterval(UpdateTime, 1000);

		return () => {
			clearInterval(clockTick);
		};

	}, []);

	return (
		<Flex>
			<Card
				className={scss.clock}
				classNameContentArea={scss.clockContent}
				px={0}
				py={0}
				outlined={true}>
				<Flex alignItems="center" columnar={true}>
					<AccessTimeIcon />
					{currentTime}
				</Flex>
			</Card>
		</Flex>
	);

};


export default UiClock;
