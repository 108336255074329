import {Flex} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import Chip from "@mui/material/Chip";

import withBasketLoading from "Hoc/withBasketLoading.js";
import BasketService from "Services/BasketService.js";

import DiscountIcon from "@mui/icons-material/LocalOfferOutlined";

export default withBasketLoading(({discounts, item, BasketLoading}) => {

	return (
		<Flex columnar={true} flexWrap={true}>
			{
				discounts.map((discount, key) => {

					const {Code, ReductionAbsolute, ReductionPercent} = discount.Discount;
					const discountQty = Math.min(discount.DiscountQty, item.Quantity);

					let label = "";

					if (discountQty !== item.Quantity) {
						label += `[${discountQty}x]`;
					}

					label += ` ${Code} (`;

					if (ReductionAbsolute) {
						label += `-${Localisation.formatCurrency((ReductionAbsolute * discountQty))}`;
					}
					else if (ReductionPercent) {
						label += `-${ReductionPercent}%`;
					}

					label += `)`;

					return (
						<Chip
							disabled={BasketLoading}
							key={key}
							icon={<DiscountIcon />}
							label={label.trim()}
							onDelete={() => BasketService.removeDiscountCode(Code)}
							variant="outlined" />
					);

				})
			}
		</Flex>
	);

});
